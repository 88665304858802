import Vue from 'vue'
import VueRouter from 'vue-router'
import Nest from '@/views/Nest.vue'
import UserEditor from '@/components/UserEditor.vue'
import CompanyEditor from '@/components/CompanyEditor.vue'
import NestEditor from '@/components/NestEditor.vue'
import Nests from '@/views/Nests.vue'
import Verify from '@/views/Verify.vue'
import Users from '@/views/Users.vue'
import Companies from '@/views/Companies.vue'
import Jobs from '@/views/Jobs.vue'
import Home from '@/views/Home.vue'
import JobEditor from '@/components/JobEditor.vue'
import Multiguard from 'vue-router-multiguard'
import Store from '@/store/store'

Vue.use(VueRouter)

const waitFor = (to, from, next) => {
  const iv = setInterval(() => {
    if (Store.getters.ac) {
      clearInterval(iv)
      next()
    }
  }, 100)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/users/editor/:id',
    name: 'userEditor',
    component: UserEditor,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        // Create
        if (to.params.id === 'new') {
          if (Store.getters.ac.can(Store.state.auth.user.role).createAny('users').granted) {
            next()
          } else {
            next('/')
          }
        // Update
        } else {
          if (
            Store.getters.ac.can(Store.state.auth.user.role).updateAny('users').granted ||
            (Store.getters.ac.can(Store.state.auth.user.role).updateOwn('users').granted && Store.state.auth.user._id === to.params.id)
          ) {
            next()
          } else {
            next('/')
          }
        }
      }
    ])
  },
  {
    path: '/companies/editor/:id',
    name: 'companyEditor',
    component: CompanyEditor,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        // Create
        if (to.params.id === 'new') {
          if (Store.getters.ac.can(Store.state.auth.user.role).createAny('companies').granted) {
            next()
          } else {
            next('/')
          }
        // Update
        } else {
          if (
            Store.getters.ac.can(Store.state.auth.user.role).updateAny('companies').granted
          ) {
            next()
          } else {
            next('/')
          }
        }
      }
    ])
  },
  {
    path: '/nests/editor/:id',
    name: 'nestEditor',
    component: NestEditor,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        // Create
        if (to.params.id === 'new') {
          if (Store.getters.ac.can(Store.state.auth.user.role).createAny('nests').granted) {
            next()
          } else {
            next('/')
          }
        // Update
        } else {
          if (
            Store.getters.ac.can(Store.state.auth.user.role).updateAny('nests').granted
          ) {
            next()
          } else {
            next('/')
          }
        }
      }
    ])
  },
  {
    path: '/jobs/editor/:id',
    name: 'jobEditor',
    component: JobEditor,
    beforeEnter: Multiguard([
      waitFor,
      async (to, from, next) => {
        // Create
        if (to.params.id === 'new') {
          if (Store.getters.ac.can(Store.state.auth.user.role).createAny('jobs').granted) {
            next()
          } else {
            next('/')
          }
          // Update
        } else {
          const job = await Store.dispatch('jobs/get', to.params.id)
          if (
            Store.getters.ac.can(Store.state.auth.user.role).updateAny('jobs').granted ||
            (
              Store.getters.ac.can(Store.state.auth.user.role).updateOwn('jobs').granted &&
              (
                Store.state.auth.user.role === job.type ||
                Store.state.auth.user._id === job.from ||
                (
                  job.done &&
                  Store.state.auth.user._id === job.done.by
                )
              )
            )
          ) {
            next()
          } else {
            next('/')
          }
        }
      }
    ])
  },
  {
    path: '/nests/:id',
    name: 'nestDetail',
    component: Nest,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          Store.getters.ac.can(Store.state.auth.user.role).readAny('nests').granted
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/nests',
    name: 'nestList',
    component: Nests,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          Store.getters.ac.can(Store.state.auth.user.role).createAny('nests').granted
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          Store.getters.ac.can(Store.state.auth.user.role).createAny('users').granted
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/companies',
    name: 'companies',
    component: Companies,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          Store.getters.ac.can(Store.state.auth.user.role).createAny('companies').granted
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/jobs',
    name: 'jobs',
    component: Jobs,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          Store.getters.ac.can(Store.state.auth.user.role).createAny('jobs').granted
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/users/jobs/:id',
    name: 'myJobs',
    component: Jobs,
    beforeEnter: Multiguard([
      waitFor,
      (to, from, next) => {
        if (
          Store.getters.ac.can(Store.state.auth.user.role).readOwn('jobs').granted
        ) {
          next()
        } else {
          next('/')
        }
      }
    ])
  },
  {
    path: '/verify/:type/:token',
    name: 'verify',
    component: Verify
  }
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
