<template>
  <v-container>
    <v-card>
      <v-form v-model="formValid">
        <v-card-title>
          Nest Editor
        </v-card-title>
        <v-card-text>
          <template v-if="$route.params.id !== 'new'">
          <v-row v-if="!computedNest.isActive">
            <v-col>
              <v-alert
              dark
              dense
              tile
              color="warning"
              text
            >
              <slot name="prepend">
                <v-icon
                  color="warning"
                  class="mr-1"
                  small
                >
                  fas fa-exclamation-triangle
                </v-icon>
              </slot>
              Das Nest ist archiviert!
              </v-alert>
            </v-col>
          </v-row>
          </template>
        <v-row
          v-if="
            ac.can($store.state.auth.user.role).updateAny('nests').granted &&
            (
              ac.can($store.state.auth.user.role).updateAny('nests').attributes.includes('*') ||
              ac.can($store.state.auth.user.role).updateAny('nests').attributes.includes('name')
            )
          "
        >
          <v-col
            cols="12"
          >
            <v-text-field
              label="Name"
              v-model="name"
              :rules="[rules.required, rules.shortText]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-if="
            ac.can($store.state.auth.user.role).updateAny('nests').granted &&
            (
              ac.can($store.state.auth.user.role).updateAny('nests').attributes.includes('*') ||
              ac.can($store.state.auth.user.role).updateAny('nests').attributes.includes('info')
            )
          "
        >
          <v-col
            cols="12"
          >
            <v-text-field
              label="Beschreibung"
              v-model="info"
              :rules="[rules.longText]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-if="
            ac.can($store.state.auth.user.role).updateAny('nests').granted &&
            (
              ac.can($store.state.auth.user.role).updateAny('nests').attributes.includes('*') ||
              ac.can($store.state.auth.user.role).updateAny('nests').attributes.includes('path')
            )
          "
        >
          <v-col
            cols="12"
          >
            <v-text-field
              label="Pfad"
              v-model="path"
              :rules="[rules.required, rules.longText]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        </v-card-text>
        <v-card-actions class="pa-4">
          <template
            v-if="
              ac.can($store.state.auth.user.role).updateAny('nests').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('nests').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('nests').attributes.includes('isActive')
              )
            "
          >
            <template v-if="$route.params.id !== 'new'">
              <v-btn
                v-if="computedNest.isActive"
                outlined
                color="error"
                @click="trashNest()"
              >
                Archivieren
              </v-btn>
              <v-btn
                v-else
                outlined
                color="warning"
                @click="untrashNest()"
              >
                Aktivieren
              </v-btn>
            </template>
          </template>
          <v-spacer>
          </v-spacer>
          <v-btn
            outlined
            color="primary"
            :disabled="!formValid"
            @click="saveNest()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
    formValid: false,
    name: '',
    info: '',
    path: ''
  }),
  mounted () {
    this.adapt()
  },
  methods: {
    ...mapActions('nests', {
      createNest: 'create',
      patchNest: 'patch'
    }),
    adapt () {
      if (this.computedNest) {
        this.name = this.computedNest.name
        this.info = this.computedNest.info
        this.path = this.computedNest.path
      }
    },
    saveNest () {
      const map = {
        name: this.name,
        info: this.info,
        path: this.path
      }
      if (this.$route.params.id !== 'new') {
        this.patchNest([this.$route.params.id, map, { }])
          .then((result) => this.$router.push('/nests'))
      } else {
        this.createNest([map, {}])
          .then(result => this.$router.push('/nests'))
      }
    },
    trashNest () {
      const map = {
        isActive: false
      }
      this.patchNest([this.$route.params.id, map, { }])
        .then((result) => this.$router.push('/nests'))
    },
    untrashNest () {
      const map = {
        isActive: true
      }
      this.patchNest([this.$route.params.id, map, { }])
        .then((result) => this.$router.push('/nests'))
    }
  },
  computed: {
    ...mapGetters([
      'rules',
      'ac'
    ]),
    ...mapGetters('nests', {
      nests: 'list',
      getNest: 'get'
    }),
    computedNest () {
      if (this.nests) {
        return this.getNest(this.$route.params.id)
      } else {
        return {}
      }
    }
  },
  watch: {
    computedNest () {
      this.adapt()
    }
  }
}
</script>
