var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',[_c('v-form',{model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-card-title',[_vm._v(" Nest Editor ")]),_c('v-card-text',[(_vm.$route.params.id !== 'new')?[(!_vm.computedNest.isActive)?_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"dark":"","dense":"","tile":"","color":"warning","text":""}},[_vm._t("prepend",function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"color":"warning","small":""}},[_vm._v(" fas fa-exclamation-triangle ")])]}),_vm._v(" Das Nest ist archiviert! ")],2)],1)],1):_vm._e()]:_vm._e(),(
          _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nests').granted &&
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nests').attributes.includes('*') ||
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nests').attributes.includes('name')
          )
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Name","rules":[_vm.rules.required, _vm.rules.shortText]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1):_vm._e(),(
          _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nests').granted &&
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nests').attributes.includes('*') ||
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nests').attributes.includes('info')
          )
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Beschreibung","rules":[_vm.rules.longText]},model:{value:(_vm.info),callback:function ($$v) {_vm.info=$$v},expression:"info"}})],1)],1):_vm._e(),(
          _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nests').granted &&
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nests').attributes.includes('*') ||
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nests').attributes.includes('path')
          )
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Pfad","rules":[_vm.rules.required, _vm.rules.longText]},model:{value:(_vm.path),callback:function ($$v) {_vm.path=$$v},expression:"path"}})],1)],1):_vm._e()],2),_c('v-card-actions',{staticClass:"pa-4"},[(
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nests').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nests').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('nests').attributes.includes('isActive')
            )
          )?[(_vm.$route.params.id !== 'new')?[(_vm.computedNest.isActive)?_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.trashNest()}}},[_vm._v(" Archivieren ")]):_c('v-btn',{attrs:{"outlined":"","color":"warning"},on:{"click":function($event){return _vm.untrashNest()}}},[_vm._v(" Aktivieren ")])]:_vm._e()]:_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary","disabled":!_vm.formValid},on:{"click":function($event){return _vm.saveNest()}}},[_vm._v(" Speichern ")])],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }