<template>
  <div>
    <v-row>
      <v-col>
        <v-text-field
          label="Suche ..."
          v-model="searchString"
          clearable
          clear-icon="fas fa-times-circle"
        >
          <v-icon
            slot="prepend-inner"
            small
            class="mt-1 mr-1"
            color="#bbb"
          >fas fa-search</v-icon>
        </v-text-field>
        <v-checkbox
          dense
          hide-details
          v-if="$store.state.auth.user.role === 'administration'"
          v-model="showConnects"
          label="Connects anzeigen"
        ></v-checkbox>
        <v-checkbox
          dense
          hide-details
          v-if="$store.state.auth.user.role === 'administration'"
          v-model="showLightButtonLed"
          label="Lichtschalterlicht anzeigen"
        ></v-checkbox>
        <v-checkbox
          dense
          hide-details
          v-if="$store.state.auth.user.role === 'administration'"
          v-model="showMotionAtDoor"
          label="Bewegung am Eingang anzeigen"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row
      class="mt-3"
    >
      <v-col>
        <v-card style="overflow: hidden">
          <v-card-text>
            <v-timeline v-if="computedFilteredHistoricValues.length > 0" align-top dense>
              <v-timeline-item
                v-for="(item, i) in computedFilteredHistoricValues"
                :key="i"
                :color="itemColor(item)"
                fill-dot
                :hide-dot="item.type === 'divider'"
              >
                <template v-slot:icon>
                    <v-icon
                      v-if="item.type === 'nukiLocked' && item.value !== 'true' && item.value !== 'false' && item.value !== ''"
                      small
                      color="#fff"
                    >
                      <template v-if="item.type === 'nukiLocked'">
                        {{ stateIcons[item.type][intToHex(parseInt(item.value))] ? stateIcons[item.type][intToHex(parseInt(item.value))] : 'fas fa-exclamation-triangle' }}
                      </template>
                      <template v-else>
                        {{stateIcons[item.type][intToHex(parseInt(item.value))]}}
                      </template>
                    </v-icon>
                    <v-icon
                      v-else-if="item.value !== ''"
                      small
                      color="#fff"
                    >
                      {{item.value === 'true' || item.value === 'false' ? (stateIcons[item.type] ? stateIcons[item.type][item.value]: '') : stateIcons[item.type] ? stateIcons[item.type].true : ''}}
                    </v-icon>
                    <v-icon
                      v-else
                      small
                      color="#fff"
                    >
                      {{stateIcons[item.type].true}}
                    </v-icon>
                </template>
                <template v-if="item.type === 'divider'">
                  <div class="my-3 ml-3">
                    <div style="position: absolute; top: 0px; color: #bbb;" class="body-1">
                      {{ $moment(item.updatedAt).format('DD.MM.YYYY') }}
                    </div>
                    <v-divider style="margin-left: 100px; margin-right: 70px;"></v-divider>
                  </div>
                </template>
                <template v-else-if="item.type === 'job'">
                  <Job :job="getJob(item.value)"></Job>
                </template>
                <template v-else>
                  <v-alert class="my-0 py-0">
                      <div class="caption font-weight-bold">
                        {{$moment(item.updatedAt).format('HH:mm:ss')}}{{item.from ? ' von ' + getUser(item.from).fullName : ''}}
                      </div>
                      <template v-if="Array.isArray(item.value)">
                        <div
                          class="mb-1"
                          v-if="item.type === 'motion'"
                        >
                          Bewegung Liegefläche erkannt:
                        </div>
                        <div
                          class="mb-1"
                          v-if="item.type === 'motionAtDoor'"
                        >
                          Bewegung Eingang erkannt:
                        </div>
                        <template
                          v-if="expandedItems.includes(item._id)"
                        >
                          <template
                            v-for="(entry, i) in item.value"
                          >
                          <div
                            :key="i"
                            v-if="entry.value === 'true'"
                          >
                            <template
                              v-if="item.type === 'motion' || item.type === 'motionAtDoor'"
                            >
                              {{$moment(entry.createdAt).format('HH:mm:ss')}} Uhr
                            </template>
                            <template v-else>
                              {{$moment(entry.createdAt).format('HH:mm:ss')}} Uhr: {{stateTexts[item.type][entry.value]}}
                            </template>
                          </div>
                          </template>
                        </template>
                        <template
                          v-else
                        >
                          <div>
                            {{$moment(item.value.filter(obj => obj.value === "true")[0].createdAt).format('HH:mm:ss')}} Uhr
                          </div>
                          <div>
                            <v-btn
                              small
                              text
                              class="pb-2"
                              @click="expandedItems.push(item._id)"
                            >
                              ...
                            </v-btn>
                          </div>
                          <div>
                            {{$moment(item.value.filter(obj => obj.value === "true")[item.value.filter(obj => obj.value === "true").length - 1].createdAt).format('HH:mm:ss')}} Uhr
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <!-- STATE or BOOLEAN -->
                        <div v-if="item.type === 'state' || item.value === 'true' || item.value === 'false'">
                          {{stateTexts[item.type] ? stateTexts[item.type][item.value] : 'Unbekannter Typ: ' + item.type + ' - ' + item.value}}
                        </div>
                        <!-- Nuki -->
                        <div v-else-if="item.type === 'nukiLocked'">
                          {{item.value !== '' ? (stateTexts[item.type][intToHex(parseInt(item.value))] ? stateTexts[item.type][intToHex(parseInt(item.value))] : 'Nuki-Value: ' + intToHex(parseInt(item.value))) : 'Ungültiger Wert'}}<br>
                        </div>
                        <!-- Dates
                        <div v-else-if="$moment(item.value).isValid()">
                          {{stateTexts[item.type].true}}<br>
                        </div>
                        -->
                        <!-- Others -->
                        <div v-else>
                          <template v-if="
                            item.type === 'battery' ||
                            item.type === 'humidityInside' ||
                            item.type === 'humidityOutside' ||
                            item.type === 'tempInside' ||
                            item.type === 'tempOutside' ||
                            item.type === 'fan' ||
                            item.type === 'victronReceiveCount' ||
                            item.type === 'victronYieldToday' ||
                            item.type === 'victronExternalDeviceLoad' ||
                            item.type === 'victronChargingCurrent' ||
                            item.type === 'victronSolarPower' ||
                            item.type === 'victronBatteryVoltage' ||
                            item.type === 'nukiBatterystate'
                          ">
                            {{item.value}}
                          </template>
                          <span v-if="item.type === 'battery' || item.type === 'victronBatteryVoltage' || item.type === 'humidityInside' || item.type === 'humidityOutside'"> %</span>
                          <span v-if="item.type === 'tempInside' || item.type === 'tempOutside'"> °C</span>
                          {{stateTexts[item.type] ? stateTexts[item.type].true : 'Unbekannter Typ: ' + item.type + ' - ' + item.value}}
                        </div>
                      </template>
                  </v-alert>
                </template>
              </v-timeline-item>
            </v-timeline>
            <v-row v-else>
              <v-col class="body-1">
                Keine Einträge gefunden.
              </v-col>
            </v-row>
            <v-card-actions v-if="skip < (computedLimit - limit)">
              <v-btn
                @click="nextPage()"
                style="margin-left: -5px;"
                fab
                small
              >
                <v-icon>
                  fa fa-plus
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import { makeFindMixin } from 'feathers-vuex'
import { mapGetters } from 'vuex'
import Job from '@/components/Job.vue'

export default {
  mixins: [makeFindMixin({
    service () { return this.computedNestPath },
    name: 'dataService',
    items: 'dataItems',
    watch: true
  })],
  components: {
    Job
  },
  props: [
    'nest'
  ],
  data: () => ({
    limit: 10,
    skip: 0,
    searchString: '',
    showConnects: false,
    showLightButtonLed: false,
    showMotionAtDoor: false,
    expandedItems: []
  }),
  computed: {
    ...mapGetters([
      'stateIcons',
      'stateTexts',
      'ac'
    ]),
    ...mapGetters('jobs', {
      getJob: 'get'
    }),
    ...mapGetters('users', {
      getUser: 'get'
    }),
    ...mapGetters('nests', {
      getNest: 'get'
    }),
    computedNestPath () {
      return this.nest.path
    },
    dataServiceParams () {
      // TODO: Filter selected falses
      return { paginate: false, query: { type: { $nin: this.computedNinArray }, $limit: this.limit, $skip: this.skip, $sort: { updatedAt: -1 } } }
    },
    computedLimit () {
      if (this.$store.state[this.nest.path].pagination.default) {
        return this.$store.state[this.nest.path].pagination.default.mostRecent.total
      } else {
        return 0
      }
    },
    computedNinArray () {
      const props = [
        'weatherColdestTemp',
        'openBelowNDegrees',
        'timeframeStart',
        'timeframeEnd',
        'tempInside',
        'tempInsideTH',
        'tempOutside',
        'humidityInside',
        'humidityOutside',
        'battery',
        'victronBatteryVoltage',
        'victronSolarPower',
        'victronChargingCurrent',
        'victronExternalDeviceLoad',
        'victronYieldToday',
        'victronReceiveCount',
        'nukiBatterystate',
        'fan',
        'tempBattery',
        'doorLocked',
        'doorOpenerRelais',
        'gps'
      ]
      return props
    },
    computedHistoricValues () {
      let tmpValues = this.$store.getters[this.nest.path + '/list'].filter(obj => obj.isActive)
      if (!this.showConnects) {
        tmpValues = tmpValues.filter(obj => obj.type !== 'connect')
      }
      if (!this.showLightButtonLed) {
        tmpValues = tmpValues.filter(obj => obj.type !== 'lightButtonLed')
      }
      if (!this.showMotionAtDoor) {
        tmpValues = tmpValues.filter(obj => obj.type !== 'motionAtDoor')
      }
      tmpValues = tmpValues.filter(obj =>
        (obj.type !== 'lightButton' || obj.value === 'true' || this.$moment(obj.value).isValid()) &&
        obj.type !== 'tempInside' &&
        obj.type !== 'tempInsideTH' &&
        obj.type !== 'tempOutside' &&
        obj.type !== 'humidityInside' &&
        obj.type !== 'humidityOutside' &&
        obj.type !== 'battery' &&
        obj.type !== 'victronBatteryVoltage' &&
        obj.type !== 'victronSolarPower' &&
        obj.type !== 'victronChargingCurrent' &&
        obj.type !== 'victronExternalDeviceLoad' &&
        obj.type !== 'victronYieldToday' &&
        obj.type !== 'victronReceiveCount' &&
        obj.type !== 'nukiBatterystate' &&
        obj.type !== 'tempBattery' &&
        obj.type !== 'fan' &&
        obj.type !== 'weatherColdestTemp' &&
        obj.type !== 'openBelowNDegrees' &&
        obj.type !== 'timeframeStart' &&
        obj.type !== 'timeframeEnd'
      )
      tmpValues.sort((a, b) => { return new Date(b.updatedAt) - new Date(a.updatedAt) })
      tmpValues = tmpValues.filter((obj) => this.ac.can(this.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('*') || this.ac.can(this.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes(obj.type))
      let i = 0
      const ids = []
      for (const val of tmpValues) {
        if (i === 0 || !this.$moment(val.updatedAt).isSame(this.$moment(tmpValues[i - 1].updatedAt), 'day')) {
          ids.push(val._id)
        }
        ++i
      }
      for (const id of ids) {
        if (tmpValues.findIndex((obj) => obj.type === 'divider' && obj.updatedAt === tmpValues.find((obj) => obj._id === id).updatedAt) === -1) {
          tmpValues.splice(tmpValues.findIndex((obj) => obj._id === id), 0, { type: 'divider', updatedAt: tmpValues.find((obj) => obj._id === id).updatedAt })
        }
      }
      return tmpValues
    },
    computedFilteredHistoricValues () {
      let tmpValues = this.computedHistoricValues
      if (this.searchString !== '' && this.searchString !== null) {
        tmpValues = tmpValues.filter(
          (obj) => {
            return (
              // Type found
              obj.type.indexOf(this.searchString) !== -1) ||
              // Description for boolean value found
              (obj.type !== 'divider' && obj.type !== 'job' && (obj.value.toString() === 'true' || obj.value.toString() === 'false') && (this.stateTexts[obj.type][obj.value].toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)) ||
              // Description for numeric or string value found
              (obj.type !== 'divider' && obj.type !== 'job' && this.stateTexts[obj.type].true && (this.stateTexts[obj.type].true.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)) ||
              // Value found
              (obj.type !== 'divider' && obj.type !== 'job' && obj.value.indexOf(this.searchString.toLowerCase()) !== -1) ||
              // Updated date found
              (obj.type !== 'divider' && obj.type !== 'job' && (this.$moment(obj.updatedAt).format('HH:mm:ss').indexOf(this.searchString) !== -1)) ||
              // State text found
              (obj.type === 'state' && this.stateTexts[obj.type][obj.value].toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) ||
              // Job created date found
              (obj.type === 'job' && (this.$moment(obj.createdAt).format('HH:mm:ss').indexOf(this.searchString) !== -1)) ||
              // Job done date found
              (obj.type === 'job' && this.getJob(obj.value) && this.getJob(obj.value).done.dt && (this.$moment(this.getJob(obj.value).done.dt).format('HH:mm:ss').indexOf(this.searchString) !== -1)) ||
              // Job from found
              (obj.type === 'job' && this.getJob(obj.value) && (this.getUser(this.getJob(obj.value).from)).fullName.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) ||
              // Job done by found
              (obj.type === 'job' && this.getJob(obj.value) && this.getJob(obj.value).done.by && (this.getUser(this.getJob(obj.value).done.by)).fullName.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) ||
              // Job type found
              (obj.type === 'job' && this.getJob(obj.value) && (this.getJob(obj.value).type.indexOf(this.searchString.toLowerCase()) !== -1)) ||
              // Job type string found
              (obj.type === 'job' && this.getJob(obj.value) && (this.stateTexts[this.getJob(obj.value).type].true.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)) ||
              // "Aufgabe" found
              (obj.type === 'job' && ('aufgabe'.indexOf(this.searchString.toLowerCase()) !== -1)) ||
              // Job comment found
              (obj.type === 'job' && this.getJob(obj.value) && (this.getJob(obj.value).comment.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1))
          }
        )
      }
      return tmpValues
    }
  },
  methods: {
    nextPage () {
      this.skip = this.skip + this.limit
    },
    itemColor (item) {
      if (item.type === 'state') {
        return 'primary'
      } else if (item.type === 'job') {
        if (this.getJob(item.value) && this.getJob(item.value).done.dt) {
          return 'grey'
        } else {
          return 'warning'
        }
      } else {
        return 'grey'
      }
    },
    intToHex (integer) {
      let number = (integer).toString(16).toUpperCase()
      if ((number.length % 2) > 0) { number = '0' + number }
      return '0x' + number
    }
  },
  watch: {
    computedNestPath () {
      this.skip = 0
      this.findDataService()
    }
  }
}
</script>

<style>
 .v-timeline {
    margin-left: -25px !important;
    width: calc(100% + 40px) !important;
  }
</style>
