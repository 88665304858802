<template>
  <v-container
    fluid
  >
    <v-row v-if="$route.name !== 'myJobs' && ac.can($store.state.auth.user.role).createAny('jobs').granted">
      <v-col>
        <v-btn
          @click="$router.push('/jobs/editor/new')"
          outlined
        >
          <v-icon
            x-small
            class="mr-2"
            color="secondary"
          >
            fa fa-plus
          </v-icon>
          Neue Aufgabe
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col class="py-0">
        <v-text-field
          label="Suche ..."
          clearable
          clear-icon="fas fa-times-circle"
          v-model="searchString"
        >
          <v-icon
            slot="prepend-inner"
            small
            class="mt-1 mr-1"
            color="#bbb"
          >fas fa-search</v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="$route.name === 'myJobs'">
      <v-col class="py-0">
        <v-switch
          v-model="concernsMe"
          label="Mein Bereich"
        ></v-switch>
      </v-col>
      <v-col class="py-0">
        <v-switch
          v-model="fromMe"
          label="Von mir eingereicht"
        ></v-switch>
      </v-col>
      <v-col class="py-0">
        <v-switch
          v-model="doneByMe"
          label="Von mir erledigt"
        ></v-switch>
      </v-col>
    </v-row>
    <v-row
      v-for="(job, i) in computedfilteredJobs"
      :key="i+'job'"
    >
      <v-col>
        <Job mode="list" :job="job"></Job>
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col>
        <v-btn
          v-if="jobs.length < computedLimit"
          @click="nextPage()"
          block
          outlined
        >
          Ältere laden
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { makeFindMixin } from 'feathers-vuex'
import Job from '@/components/Job.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'jobs',
  mixins: [makeFindMixin({ service: 'jobs', watch: true })],
  components: {
    Job
  },
  data: () => ({
    limit: 5,
    skip: 0,
    searchString: '',
    doneByMe: true,
    concernsMe: true,
    fromMe: true
  }),
  mounted () {
    if (this.$route.name === 'myJobs' && this.$route.params.id !== this.$store.state.auth.user._id) {
      this.$router.push('/')
    }
  },
  methods: {
    nextPage () {
      this.skip = this.skip + this.limit
    }
  },
  computed: {
    ...mapGetters([
      'stateIcons',
      'stateTexts',
      'ac'
    ]),
    ...mapGetters('jobs', {
      jobs: 'list'
    }),
    ...mapGetters('users', {
      getUser: 'get'
    }),
    ...mapGetters('nests', {
      getNest: 'get'
    }),
    jobsParams () {
      return { query: { $limit: this.limit, $skip: this.skip, $sort: { updatedAt: -1 } } }
    },
    computedfilteredJobs () {
      let tmpValues = this.jobs
      // User filters
      if (this.$route.name === 'myJobs') {
        if (this.$store.state.auth.user.role !== 'administration') {
          tmpValues = tmpValues.filter(obj => !this.getNest(obj.nest).name.startsWith('Test'))
        }
        tmpValues = tmpValues.filter((obj) =>
          (this.fromMe && (obj.from === this.$store.state.auth.user._id)) ||
          ((this.concernsMe && this.$store.state.auth.user.role === 'administration') || (this.concernsMe && this.$store.state.auth.user.role === 'supervision') || (this.concernsMe && obj.type === this.$store.state.auth.user.role)) ||
          (this.doneByMe && (obj.done && (obj.done.by === this.$store.state.auth.user._id)))
        )
      }
      // Text filters
      if (this.searchString !== '') {
        tmpValues = tmpValues.filter((obj) =>
          (this.$moment(obj.createdAt).format('HH:mm:ss').indexOf(this.searchString) !== -1) ||
          (obj.done && this.$moment(obj.done.dt).format('HH:mm:ss').indexOf(this.searchString) !== -1) ||
          (this.getUser(obj.from).fullName.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) ||
          (obj.type.indexOf(this.searchString.toLowerCase()) !== -1) ||
          (this.stateTexts[obj.type].true.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) ||
          (obj.comment && obj.comment !== '' && obj.comment.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) ||
          (this.getNest(obj.nest).name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)
        )
      }
      return tmpValues.sort((a, b) => { return new Date(b.updatedAt) - new Date(a.updatedAt) })
    },
    computedLimit () {
      if (this.$store.state.jobs.pagination.default) {
        return this.$store.state.jobs.pagination.default.mostRecent.total
      } else {
        return 0
      }
    }
  }
}
</script>
