<template>
  <v-container>
    <v-card>
      <v-form v-model="formValid">
        <v-card-title>
          Job Editor
        </v-card-title>
        <v-card-text>
          <template v-if="$route.params.id !== 'new'">
          <v-row v-if="computedJob && !computedJob.isActive">
            <v-col>
              <v-alert
              dark
              dense
              tile
              color="warning"
              text
            >
              <slot name="prepend">
                <v-icon
                  color="warning"
                  class="mr-1"
                  small
                >
                  fas fa-exclamation-triangle
                </v-icon>
              </slot>
              Die Aufgabe ist archiviert!
              </v-alert>
            </v-col>
          </v-row>
          </template>
        <v-row
          v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('jobs').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('jobs').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('jobs').attributes.includes('nest')
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('jobs').granted &&
              (
                $store.state.auth.user._id === computedJob.from ||
                $store.state.auth.user.role === computedJob.type ||
                (computedJob.done && computedJob.done.by === $store.state.auth.user._id)
              ) &&
              (
                ac.can($store.state.auth.user.role).updateOwn('jobs').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateOwn('jobs').attributes.includes('nest')
              )
            )
          "
        >
          <v-col
            cols="12"
          >
            <v-select
              label="Nest auswählen"
              :items="nests"
              item-text="name"
              item-value="_id"
              :rules="[rules.required]"
              v-model="nest"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('jobs').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('jobs').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('jobs').attributes.includes('from')
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('jobs').granted &&
              (
                $store.state.auth.user._id === computedJob.from ||
                $store.state.auth.user.role === computedJob.type ||
                (computedJob.done && computedJob.done.by === $store.state.auth.user._id)
              ) &&
              (
                ac.can($store.state.auth.user.role).updateOwn('jobs').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateOwn('jobs').attributes.includes('from')
              )
            )
          "
        >
          <v-col
            cols="12"
          >
            <v-select
              label="Aufgabensteller auswählen"
              :items="users.filter(obj => obj.isActive)"
              item-text="fullName"
              item-value="_id"
              :rules="[rules.required]"
              v-model="user"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('jobs').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('jobs').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('jobs').attributes.includes('type')
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('jobs').granted &&
              (
                $store.state.auth.user._id === computedJob.from ||
                $store.state.auth.user.role === computedJob.type ||
                (computedJob.done && computedJob.done.by === $store.state.auth.user._id)
              ) &&
              (
                ac.can($store.state.auth.user.role).updateOwn('jobs').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateOwn('jobs').attributes.includes('type')
              )
            )
          "
        >
          <v-col
            cols="12"
          >
            <v-select
              label="Aufgaben-Typ auswählen"
              :items="[{ text: 'Aufsuchende Arbeit', value: 'outreaching'}, { text: 'Reinigung', value: 'cleaning'}, { text: 'Reparatur / Wartung', value: 'maintenance'},]"
              :rules="[rules.required]"
              v-model="type"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('jobs').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('jobs').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('jobs').attributes.includes('comment')
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('jobs').granted &&
              (
                $store.state.auth.user._id === computedJob.from ||
                $store.state.auth.user.role === computedJob.type ||
                (computedJob.done && computedJob.done.by === $store.state.auth.user._id)
              ) &&
              (
                ac.can($store.state.auth.user.role).updateOwn('jobs').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateOwn('jobs').attributes.includes('comment')
              )
            )
          "
        >
          <v-col
            cols="12"
          >
            <v-text-field
              label="Kommentar"
              v-model="comment"
              :rules="[rules.longText, rules.required]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <template
          v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('jobs').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('jobs').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('jobs').attributes.includes('followJobs')
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('jobs').granted &&
              (
                $store.state.auth.user._id === computedJob.from ||
                $store.state.auth.user.role === computedJob.type ||
                (computedJob.done && computedJob.done.by === $store.state.auth.user._id)
              ) &&
              (
                ac.can($store.state.auth.user.role).updateOwn('jobs').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateOwn('jobs').attributes.includes('followJobs')
              )
            )
          "
        >
          <v-row>
            <v-col>
              Mögliche Folge-Aufgaben:
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox v-model="followJobs" label="Reinigung" value="cleaning"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox v-model="followJobs" label="Reparatur / Wartung" value="maintenance"></v-checkbox>
            </v-col>
          </v-row>
        </template>
        </v-card-text>
        <v-card-actions class="pa-4">
          <template
            v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('jobs').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('jobs').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('jobs').attributes.includes('isActive')
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('jobs').granted &&
              (
                $store.state.auth.user._id === computedJob.from ||
                $store.state.auth.user.role === computedJob.type ||
                (computedJob.done && computedJob.done.by === $store.state.auth.user._id)
              ) &&
              (
                ac.can($store.state.auth.user.role).updateOwn('jobs').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateOwn('jobs').attributes.includes('isActive')
              )
            )
          "
          >
            <template v-if="$route.params.id !== 'new'">
              <v-btn
                v-if="computedJob && computedJob.isActive"
                outlined
                color="error"
                @click="trashJob()"
              >
                Archivieren
              </v-btn>
              <v-btn
                v-else
                outlined
                color="warning"
                @click="untrashJob()"
              >
                Aktivieren
              </v-btn>
            </template>
          </template>
          <v-spacer>
          </v-spacer>
          <v-btn
            outlined
            color="primary"
            :disabled="!formValid"
            @click="saveJob()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
    formValid: false,
    type: undefined,
    comment: '',
    nest: undefined,
    user: undefined,
    followJobs: []
  }),
  mounted () {
    if (this.computedJob) {
      this.adapt()
    } else if (this.$route.params.id !== 'new') {
      this.requestJob(this.$route.params.id)
    }
  },
  methods: {
    ...mapActions('jobs', {
      createJob: 'create',
      patchJob: 'patch',
      requestJob: 'get'
    }),
    adapt () {
      if (this.computedJob) {
        this.comment = this.computedJob.comment
        this.type = this.computedJob.type
        this.user = this.computedJob.from
        this.nest = this.computedJob.nest
        this.followJobs = this.computedJob.followJobs
      } else {
        this.user = this.$store.state.auth.user._id
      }
    },
    saveJob () {
      const map = {
        type: this.type,
        comment: this.comment,
        nest: this.nest,
        from: this.user,
        followJobs: this.followJobs
      }
      if (this.$route.params.id !== 'new') {
        this.patchJob([this.$route.params.id, map, { }])
          .then((result) => this.$router.push('/jobs'))
          .catch((error) => console.log(error))
      } else {
        this.createJob([map, {}])
          .then(result => this.$router.push('/jobs'))
          .catch((error) => console.log(error))
      }
    },
    trashJob () {
      const map = {
        isActive: false
      }
      this.patchJob([this.$route.params.id, map, { }])
        .then((result) => this.$router.push('/jobs'))
        .catch((error) => console.log(error))
    },
    untrashJob () {
      const map = {
        isActive: true
      }
      this.patchJob([this.$route.params.id, map, { }])
        .then((result) => this.$router.push('/jobs'))
        .catch((error) => console.log(error))
    }
  },
  computed: {
    ...mapGetters([
      'rules',
      'ac'
    ]),
    ...mapGetters('nests', {
      nests: 'list',
      getNest: 'get'
    }),
    ...mapGetters('jobs', {
      getJob: 'get'
    }),
    ...mapGetters('users', {
      users: 'list'
    }),
    computedJob () {
      return this.getJob(this.$route.params.id)
    }
  },
  watch: {
    computedJob () {
      if (this.computedJob) {
        this.adapt()
      } else if (this.$route.params.id !== 'new') {
        this.requestJob(this.$route.params.id)
      }
    }
  }
}
</script>
