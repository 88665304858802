<template>
  <v-container>
    <v-card>
      <v-form v-model="formValid">
        <v-card-title>
          Organisations Editor
        </v-card-title>
        <v-card-text>
        <v-row v-if="$route.params.id !== 'new' && !computedCompany.isActive">
            <v-col>
              <v-alert
              dark
              dense
              tile
              color="warning"
              text
            >
              <slot name="prepend">
                <v-icon
                  color="warning"
                  class="mr-1"
                  small
                >
                  fas fa-exclamation-triangle
                </v-icon>
              </slot>
              Die Organisation ist archiviert!
              </v-alert>
            </v-col>
          </v-row>
        <v-row
          v-if="
            ac.can($store.state.auth.user.role).updateAny('companies').granted &&
            (
              ac.can($store.state.auth.user.role).updateAny('companies').attributes.includes('*') ||
              ac.can($store.state.auth.user.role).updateAny('companies').attributes.includes('name')
            )
          "
        >
          <v-col
            cols="12"
          >
            <v-text-field
              label="Name"
              v-model="name"
              :rules="[rules.required, rules.shortText]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        </v-card-text>
        <v-card-actions class="pa-4">
          <template
            v-if="
            ac.can($store.state.auth.user.role).updateAny('companies').granted &&
            (
              ac.can($store.state.auth.user.role).updateAny('companies').attributes.includes('*') ||
              ac.can($store.state.auth.user.role).updateAny('companies').attributes.includes('isActive')
            )
          "
          >
            <template v-if="$route.params.id !== 'new'">
              <v-btn
                v-if="computedCompany.isActive"
                outlined
                color="error"
                @click="trashCompany()"
              >
                Archivieren
              </v-btn>
              <v-btn
                v-else
                outlined
                color="warning"
                @click="untrashCompany()"
              >
                Aktivieren
              </v-btn>
            </template>
          </template>
          <v-spacer>
          </v-spacer>
          <v-btn
            outlined
            :disabled="!formValid"
            @click="saveCompany()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
    formValid: false,
    name: ''
  }),
  mounted () {
    this.adapt()
  },
  methods: {
    ...mapActions('companies', {
      createCompany: 'create',
      patchCompany: 'patch'
    }),
    adapt () {
      if (this.computedCompany) {
        this.name = this.computedCompany.name
      }
    },
    saveCompany () {
      const map = {
        name: this.name
      }
      if (this.$route.params.id !== 'new') {
        this.patchCompany([this.$route.params.id, map, { }])
          .then(result => this.$router.push('/companies'))
          .catch((error) => console.log(error))
      } else {
        this.createCompany([map, {}])
          .then(result => this.$router.push('/companies'))
          .catch((error) => console.log(error))
      }
    },
    trashCompany () {
      const map = {
        isActive: false
      }
      this.patchCompany([this.$route.params.id, map, { }])
        .then((result) => this.$router.push('/companies'))
        .catch((error) => console.log(error))
    },
    untrashCompany () {
      const map = {
        isActive: true
      }
      this.patchCompany([this.$route.params.id, map, { }])
        .then((result) => this.$router.push('/companies'))
        .catch((error) => console.log(error))
    }
  },
  watch: {
    computedCompany () {
      this.adapt()
    }
  },
  computed: {
    ...mapGetters([
      'rules',
      'ac'
    ]),
    ...mapGetters('companies', {
      companies: 'list',
      getCompany: 'get'
    }),
    computedCompany () {
      if (this.companies) {
        return this.getCompany(this.$route.params.id)
      } else {
        return {}
      }
    }
  }
}
</script>
