<template>
  <v-container
    fluid
  >
    <!-- Nest list -->
    <v-row>
      <v-col>
        <div class="display-2 grey--text font-weight-thin text-uppercase">Nester</div>
      </v-col>
    </v-row>
    <v-row
      v-if="ac.can($store.state.auth.user.role).readAny('nests').granted"
    >
      <v-col
        cols="12"
        md="6"
        lg="4"
        xl="3"
        style="cursor: pointer"
        v-for="(nest, i) in nests.filter(obj => obj.isActive).sort((a, b) => a.name.localeCompare(b.name))"
        :key="i"
        @click="$router.push('/nests/' + nest._id)"
      >
        <NestPreview :small="true" :nest="nest"></NestPreview>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import NestPreview from '@/components/NestPreview.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: {
    NestPreview
  },
  props: [
    'nests'
  ],
  computed: {
    ...mapGetters([
      'ac'
    ])
  }
}
</script>
