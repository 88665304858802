<template>
  <v-card>
    <v-card-text
      class="px-0"
    >
      <div v-if="computedHistoricValues.length > 0">
        <apexchart
          ref="chart"
          height="295px"
          type="line"
          :options="computedChartOptions"
          :series="computedSeries"
        ></apexchart>
      </div>
      <v-row v-else>
        <v-col class="body-1">
          Keine Einträge gefunden.
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions
      v-if="computedHistoricValues.length > 0"
      class="pa-4"
    >
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="nextPage()"
      >
        Ältere laden
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import { makeFindMixin } from 'feathers-vuex'
import { mapGetters } from 'vuex'

export default {
  mixins: [
    makeFindMixin({
      service () { return this.computedNestPath },
      name: 'dataService',
      items: 'dataItems',
      watch: true
    })
  ],
  props: [
    'nest',
    'type'
  ],
  data: () => ({
    limit: 100,
    skip: 0,
    searchString: '',
    klimaChartOptions: {
      tooltip: {
        x: {
          show: true,
          format: 'dd.MM., HH:mm:ss'
        }
      },
      stroke: {
        width: 5
      },
      yaxis: [
        {
          seriesName: 'Temperatur innen',
          axisBorder: {
            show: true
          },
          labels: {
            formatter: function (value) {
              return (Math.round(value * 10) / 10).toFixed(1) + '°C'
            }
          }
        },
        {
          show: false,
          seriesName: 'Temperatur innen',
          labels: {
            formatter: function (value) {
              return (Math.round(value * 10) / 10).toFixed(1) + '°C'
            }
          }
        },
        {
          seriesName: 'Luftfeuchtigkeit',
          axisBorder: {
            show: true
          },
          opposite: true,
          labels: {
            formatter: function (value) {
              return (Math.round(value * 10) / 10).toFixed(1) + '%'
            }
          }
        },
        {
          show: false,
          seriesName: 'Luftfeuchtigkeit',
          axisBorder: {
            show: true
          },
          opposite: true,
          labels: {
            formatter: function (value) {
              return (Math.round(value * 10) / 10).toFixed(1) + '%'
            }
          }
        },
        {
          seriesName: 'Lüfter',
          axisBorder: {
            show: true
          },
          opposite: true,
          labels: {
            formatter: function (value) {
              return (Math.round(value * 10) / 10).toFixed(1) + '%'
            }
          }
        }
      ],
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM yy',
            day: 'dd.MM.',
            hour: 'HH:mm'
          },
          datetimeUTC: false
        }
      },
      dataLabels: {
        enabled: false
      },
      chart: {
        toolbar: {
          show: false,
          tools: {
            download: false,
            reset: false,
            pan: false
          }
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          animateGradually: {
            enabled: false
          },
          dynamicAnimation: {
            enabled: true
          }
        }
      },
      legend: {
        show: true
      },
      colors: ['#ff0000', '#0000ff', '#FEB019', '#f2d600', '#00ffaa', '#00fff0']
    },
    techChartOptions: {
      tooltip: {
        x: {
          show: true,
          format: 'dd.MM., HH:mm:ss'
        }
      },
      stroke: {
        width: 5
      },
      yaxis: [
        {
          seriesName: 'Solar',
          axisBorder: {
            show: true
          },
          labels: {
            formatter: function (value) {
              return (Math.round(value * 10) / 10).toFixed(1) + 'W'
            }
          }
        },
        {
          seriesName: 'Verbrauch',
          axisBorder: {
            show: true
          },
          labels: {
            formatter: function (value) {
              return (Math.round(value * 10) / 10).toFixed(1) + 'mA'
            }
          }
        },
        {
          seriesName: 'Temperatur Akku',
          axisBorder: {
            show: true
          },
          labels: {
            formatter: function (value) {
              return (Math.round(value * 10) / 10).toFixed(1) + '°C'
            }
          }
        },
        {
          seriesName: 'Akkustand',
          axisBorder: {
            show: true
          },
          labels: {
            formatter: function (value) {
              return (Math.round(value * 10) / 10).toFixed(1) + 'V'
            }
          }
        },
        {
          show: false,
          seriesName: 'Akkustand Victron',
          axisBorder: {
            show: true
          },
          labels: {
            formatter: function (value) {
              return (Math.round(value * 10) / 10).toFixed(1) + 'V'
            }
          }
        }
      ],
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeFormatter: {
            year: 'yyyy',
            month: 'MMM yy',
            day: 'dd.MM.',
            hour: 'HH:mm'
          },
          datetimeUTC: false
        }
      },
      dataLabels: {
        enabled: false
      },
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: false,
            reset: false,
            pan: false
          }
        },
        animations: {
          enabled: true,
          easing: 'easeinout',
          animateGradually: {
            enabled: false
          },
          dynamicAnimation: {
            enabled: true
          }
        }
      },
      legend: {
        show: true
      },
      colors: ['#d9b500', '#666666', '#ff0000', '#00ff00', '#00aa00']
    }
  }),
  computed: {
    computedChartOptions () {
      return this[this.type + 'ChartOptions']
    },
    computedNestPath () {
      return this.nest.path
    },
    ...mapGetters([
      'stateIcons',
      'stateTexts',
      'ac'
    ]),
    ...mapGetters('nests', {
      getNest: 'get'
    }),
    dataServiceParams () {
      if (this.type === 'klima') {
        return { paginate: false, query: { type: { $in: ['tempInside', 'tempOutside', 'humidityInside', 'humidityOutside', 'fan'] }, $limit: this.limit, $skip: this.skip, $sort: { updatedAt: -1 } } }
      } else if (this.type === 'tech') {
        return { paginate: false, query: { type: { $in: ['tempBattery', 'battery', 'victronBatteryVoltage', 'victronSolarPower'] }, $limit: this.limit, $skip: this.skip, $sort: { updatedAt: -1 } } }
      } else {
        return {}
      }
    },
    computedLimit () {
      if (this.$store.state[this.nest.path].pagination.default) {
        return this.$store.state[this.nest.path].pagination.default.mostRecent.total
      } else {
        return 0
      }
    },
    computedHistoricValues () {
      let tmpValues = this.dataItems
      tmpValues = tmpValues.filter((obj) => this.ac.can(this.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes('*') || this.ac.can(this.$store.state.auth.user.role).readAny('nestMessageTypes').attributes.includes(obj.type))
      return tmpValues
    },
    computedSeries () {
      if (this.computedHistoricValues) {
        if (this.type === 'klima') {
          const tmpValue = [
            {
              name: 'Temperatur innen',
              data: this.computedHistoricValues.filter(obj => obj.type === 'tempInside').map((obj) => [this.$moment(obj.updatedAt).valueOf(), obj.value])
            },
            {
              name: 'Temperatur außen',
              data: this.computedHistoricValues.filter(obj => obj.type === 'tempOutside').map((obj) => [this.$moment(obj.updatedAt).valueOf(), obj.value])
            },
            {
              name: 'Luftfeuchtigkeit innen',
              data: this.computedHistoricValues.filter(obj => obj.type === 'humidityInside').map((obj) => [this.$moment(obj.updatedAt).valueOf(), obj.value])
            },
            {
              name: 'Luftfeuchtigkeit außen',
              data: this.computedHistoricValues.filter(obj => obj.type === 'humidityOutside').map((obj) => [this.$moment(obj.updatedAt).valueOf(), obj.value])
            },
            {
              name: 'Lüfter',
              data: this.computedHistoricValues.filter(obj => obj.type === 'fan').map((obj) => [this.$moment(obj.updatedAt).valueOf(), this.scaleFan(obj.value)])
            }
          ]
          return tmpValue
        } else if (this.type === 'tech') {
          const tmpValue = [
            {
              name: 'Solar',
              data: this.computedHistoricValues.filter(obj => obj.type === 'victronSolarPower').map((obj) => [this.$moment(obj.updatedAt).valueOf(), obj.value])
            },
            {
              name: 'Temperatur Akku',
              data: this.computedHistoricValues.filter(obj => obj.type === 'tempBattery').map((obj) => [this.$moment(obj.updatedAt).valueOf(), obj.value])
            },
            {
              name: 'Akkustand',
              data: this.computedHistoricValues.filter(obj => obj.type === 'battery').map((obj) => [this.$moment(obj.updatedAt).valueOf(), obj.value])
            },
            {
              name: 'Akkustand Victron',
              data: this.computedHistoricValues.filter(obj => obj.type === 'victronBatteryVoltage').map((obj) => [this.$moment(obj.updatedAt).valueOf(), obj.value])
            }
          ]
          return tmpValue
        } else {
          return []
        }
      } else {
        return []
      }
    }
  },
  methods: {
    nextPage () {
      this.skip = this.skip + this.limit
    },
    scaleFan (num) {
      if (num === false || num === 'false' || num === undefined) {
        return null
      }
      const inMin = 0
      const inMax = 298
      const outMin = -100
      const outMax = 100
      return (num - inMin) * (outMax - outMin) / (inMax - inMin) + outMin
    }
  },
  watch: {
    computedNestPath () {
      this.findDataService()
    }
  }
}
</script>
