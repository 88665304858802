<template>
  <v-card width="100%">
    <v-card-text>
      <v-row>
        <v-col>
          <div class="display-1">
            {{user.fullName}}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0">
          <div>
            Organisation: {{computedCompanyName}}
          </div>
        </v-col>
      </v-row>
        <v-row>
        <v-col class="py-0">
          <div>
            Aufgabe: {{stateTexts[user.role].true}}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pa-4">
      <v-btn
        outlined
        @click="$router.push('/users/editor/' + user._id)"
      >
        Bearbeiten
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    showLastUpdate: false
  }),
  props: [
    'user'
  ],
  methods: {
  },
  computed: {
    ...mapGetters([
      'stateIcons',
      'stateTexts'
    ]),
    ...mapGetters('companies', {
      getCompany: 'get'
    }),
    computedCompanyName () {
      const company = this.getCompany(this.user.company)
      if (company) {
        return company.name
      } else {
        return '-'
      }
    }
  }
}
</script>
