<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col>
        <v-btn
          v-if="ac.can($store.state.auth.user.role).createAny('jobs').granted"
          @click="$router.push('/companies/editor/new')"
          outlined
        >
          <v-icon
            x-small
            class="mr-2"
            color="secondary"
          >
            fa fa-plus
          </v-icon>
          Neue Organisation
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col>
        <v-text-field
          label="Suche ..."
          clearable
          clear-icon="fas fa-times-circle"
          v-model="searchString"
        >
          <v-icon
            slot="prepend-inner"
            small
            class="mt-1 mr-1"
            color="#bbb"
          >fas fa-search</v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row
      v-for="(company, i) in computedfilteredCompanies.filter(obj => obj.isActive)"
      :key="i+'company'"
    >
      <v-col>
        <Company :company="company"></Company>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'
import Company from '@/components/Company.vue'

export default {
  name: 'companies',
  components: {
    Company
  },
  data: () => ({
    searchString: ''
  }),
  mounted () {
  },
  methods: {
  },
  computed: {
    ...mapGetters([
      'ac'
    ]),
    ...mapGetters('companies', {
      companies: 'list'
    }),
    computedfilteredCompanies () {
      let tmpValues = this.companies
      if (this.searchString !== '') {
        tmpValues = tmpValues.filter((obj) =>
          (obj.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)
        )
      }
      return tmpValues
    }
  }
}
</script>
