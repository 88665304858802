// Bring in the imports from the feathers-client.js file.
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

// Extend the base class
class Company extends BaseModel {
  // constructor (data, options) {
  //   super(data, options)
  // }
    static modelName = 'Company'
    static instanceDefaults () {
      return {
        name: '',
        users: []
      }
    }
}
const servicePath = 'companies'
const servicePlugin = makeServicePlugin({
  Model: Company,
  service: feathersClient.service(servicePath),
  servicePath,
  idField: '_id'
})

// Optionally add service-level hooks, here:
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
