<template>
  <v-container
    fluid
  >
    <v-row
      v-if="this.ac.can(this.$store.state.auth.user.role).createAny('nests').granted"
    >
      <v-col>
        <v-btn
          @click="$router.push('/nests/editor/new')"
          outlined
        >
          <v-icon
            x-small
            class="mr-2"
            color="secondary"
          >
            fa fa-plus
          </v-icon>
          Neues Nest
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col>
        <v-text-field
          label="Suche ..."
          clearable
          clear-icon="fas fa-times-circle"
          v-model="searchString"
        >
          <v-icon
            slot="prepend-inner"
            small
            class="mt-1 mr-1"
            color="#bbb"
          >fas fa-search</v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row
      v-for="(nest, i) in computedfilteredNests.filter(obj => obj.isActive)"
      :key="i+'nest'"
    >
      <v-col>
        <NestPreview
          :nest="nest"
        ></NestPreview>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import NestPreview from '@/components/NestPreview.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'nests',
  components: {
    NestPreview
  },
  data: () => ({
    searchString: ''
  }),
  computed: {
    ...mapGetters('nests', {
      nests: 'list'
    }),
    ...mapGetters([
      'ac'
    ]),
    computedfilteredNests () {
      let tmpValues = this.nests
      if (this.searchString !== '') {
        tmpValues = tmpValues.filter((obj) =>
          (obj.name.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) ||
          (obj.info.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1) ||
          (obj.path.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)
        )
      }
      tmpValues = tmpValues.sort((a, b) => a.name.localeCompare(b.name))
      return tmpValues
    }
  }
}
</script>
