<template>
  <v-container
    fluid
  >
    <v-row>
      <v-col>
        <v-btn
          @click="$router.push('/users/editor/new')"
          outlined
        >
          <v-icon
            x-small
            class="mr-2"
            color="secondary"
          >
            fa fa-plus
          </v-icon>
          Neuer Nutzer
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col>
        <v-text-field
          label="Suche ..."
          clearable
          clear-icon="fas fa-times-circle"
          v-model="searchString"
        >
          <v-icon
            slot="prepend-inner"
            small
            class="mt-1 mr-1"
            color="#bbb"
          >fas fa-search</v-icon>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row
      v-for="(user, i) in computedfilteredUsers.filter(obj => obj.isActive)"
      :key="i+'user'"
    >
      <v-col>
        <User :user="user"></User>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'
import User from '@/components/User.vue'

export default {
  name: 'users',
  components: {
    User
  },
  data: () => ({
    searchString: ''
  }),
  mounted () {
  },
  methods: {
  },
  computed: {
    ...mapGetters('users', {
      users: 'list'
    }),
    computedfilteredUsers () {
      let tmpValues = this.users
      if (this.searchString !== '') {
        tmpValues = tmpValues.filter((obj) =>
          (obj.fullName.toLowerCase().indexOf(this.searchString.toLowerCase()) !== -1)
        )
      }
      return tmpValues
    }
  }
}
</script>
