// Bring in the imports from the feathers-client.js file.
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'
import Store from '../store'

// Extend the base class
class Nest extends BaseModel {
  // constructor (data, options) {
  //   super(data, options)
  // }
    static modelName = 'Nest'
    static instanceDefaults () {
      return {
        name: undefined,
        info: undefined,
        path: undefined,
        tempInside: undefined
      }
    }
}

const servicePath = 'nests'
const servicePlugin = makeServicePlugin({
  Model: Nest,
  service: feathersClient.service(servicePath),
  servicePath,
  idField: '_id',
  skipRequestIfExists: true
})

// Create new services on the fly
feathersClient.io.on('nests created', (msg) => {
  createNestMessagesService(msg.path)
})

// LOG DEBUG
/*
feathersClient.io.on('de/bw/ulm/n1 created', (msg) => {
  console.log('N1', JSON.stringify(msg))
})
*/

function createNestMessagesService (path) {
  const plugin = makeServicePlugin({
    Model: class NestMessage extends BaseModel {
      static modelName = 'NestMessage'
      static servicePath = path
      static namespace = path
      static instanceDefaults () {
        return {
          value: undefined,
          type: undefined
        }
      }
    },
    service: feathersClient.service(path),
    namespace: path,
    nameStyle: 'path',
    servicePath: path,
    idField: '_id'
  })
  feathersClient.service(path).hooks({
    before: {
      all: [],
      find: [],
      get: [],
      create: [],
      update: [],
      patch: [],
      remove: []
    },
    after: {
      all: [],
      find: [],
      get: [],
      create: [],
      update: [],
      patch: [],
      remove: []
    },
    error: {
      all: [],
      find: [],
      get: [],
      create: [],
      update: [],
      patch: [],
      remove: []
    }
  })
  plugin(Store)
  Store.watch((state, getters) => getters[path + '/list'], (values, oldValues) => {
    for (const val of values) {
      if (val.type === 'job') {
        // Load job if not there
        if (oldValues.findIndex((obj) => obj._id === val._id) === -1) {
          Store.dispatch('jobs/get', val.value)
        }
        // If job is loaded
        if (Store.getters['jobs/get'](val.value)) {
          // If corresponding nest path is not equal nestMessage path -> remove message from store
          if (Store.getters['nests/get'](Store.getters['jobs/get'](val.value).nest).path !== path) {
            Store.commit(path + '/removeItem', val._id)
          }
        } else {
          // Load job
          Store.dispatch('jobs/get', val.value)
            .then((result) => {
              // If corresponding nest path is not equal nestMessage path -> remove message from store
              if (val.type === 'job' && Store.getters['nests/get'](result.nest).path !== path) {
                Store.commit(path + '/removeItem', val._id)
              }
            })
        }
      }
    }
  })
}

// Optionally add service-level hooks, here:
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [(a) => {
      for (const nest of a.result.data) {
        if (!Store.getters[nest.path + '/list']) {
          createNestMessagesService(nest.path)
        }
      }
      setTimeout(() => {
        Store.commit('SET_DYNAMIC_SERVICES_READY', true)
      }, 1)
    }],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
