<template>
  <v-app>
    <!-- Navigation drawer left -->
    <v-navigation-drawer
      disable-resize-watcher
      v-if="computedShowApp"
      app
      left
      v-model="showDrawer"
      width="450px"
    >
      <!-- Drawer title -->
      <v-list-item class="pa-5 pb-3 title">
        <v-list-item-content
          @click="$router.push('/')"
          style="cursor:pointer"
        >
          {{computedTitle}}
        </v-list-item-content>
        <v-list-item-action>
          <v-btn @click="showDrawer = !showDrawer" icon>
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>

      <v-list-item
        link
        @click="$router.push('/users/editor/' + populatedUser._id)"
      >
        <v-list-item-content>
          <v-list-item-title>
            Mein Profil
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider></v-divider>
      <template
        v-if="ac.can($store.state.auth.user.role).updateOwn('jobs').granted"
      >
        <v-list-item
          link
          @click="$router.push('/users/jobs/' + $store.state.auth.user._id)"
        >
          <v-list-item-content>
            <v-list-item-title>
              Meine Aufgaben
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template
        v-if="ac.can($store.state.auth.user.role).createAny('nests').granted"
      >
        <v-list-item
          link
          @click="$router.push('/nests')"
        >
          <v-list-item-content>
            <v-list-item-title>
              Nester
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template
        v-if="ac.can($store.state.auth.user.role).createAny('users').granted"
      >
        <v-list-item
          link
          @click="$router.push('/users')"
        >
          <v-list-item-content>
            <v-list-item-title>
              Nutzer
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template
        v-if="ac.can($store.state.auth.user.role).createAny('users').granted"
      >
        <v-list-item
          link
          @click="$router.push('/companies')"
        >
          <v-list-item-content>
            <v-list-item-title>
              Organisationen
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <template
        v-if="ac.can($store.state.auth.user.role).createAny('jobs').granted"
      >
        <v-list-item
          link
          @click="$router.push('/jobs')"
        >
          <v-list-item-content>
            <v-list-item-title>
              Aufgaben
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
      </template>
      <v-list-item
          link
          @click="initLogout()"
        >
          <v-list-item-content>
            <v-list-item-title>
              Abmelden
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      <v-divider></v-divider>
    </v-navigation-drawer>
    <!-- Navigation drawer right -->
    <v-navigation-drawer
      disable-resize-watcher
      v-if="computedShowApp"
      app
      right
      v-model="showRightDrawer"
      width="450px"
    >
      <!-- Drawer title -->
      <v-list-item class="pa-5 pb-3 font-weight-bold">
        <v-spacer></v-spacer>
        <v-list-item-action>
          <v-btn @click="showRightDrawer = !showRightDrawer" icon>
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-divider></v-divider>
      <!-- Nest list -->
      <v-list color="#eee"
        v-if="ac.can($store.state.auth.user.role).readAny('nests').granted"
      >
        <template v-for="(nest, i) in computedNests.filter(obj => obj.isActive).sort((a, b) => a.name.localeCompare(b.name))">
          <v-list-item
            class="py-2 px-2"
            :key="i"
          >
            <NestPreview :small="true" :nest="nest"></NestPreview>
          </v-list-item>
          <v-divider
            :key="i + 'divider'"
            v-if="i < nest.length"
          >
          </v-divider>
        </template>
      </v-list>
    </v-navigation-drawer>
    <!-- App bar -->
    <v-app-bar
      v-if="computedShowApp"
      app
    >
      <v-app-bar-nav-icon
        @click="showDrawer = !showDrawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title v-if="getNest($route.params.id)">Nest: {{getNest($route.params.id).name}}</v-toolbar-title>
      <v-toolbar-title v-else>Hallo {{populatedUser.fullName}}!</v-toolbar-title>
      <v-spacer>
      </v-spacer>
      <v-btn
        color="secondary"
        fab
        small
        class="elevation-0"
        @click="showRightDrawer = !showRightDrawer"
      >
        <v-img
          src="/favicon-32x32.png"
          height="23"
          contain
        >
        </v-img>
      </v-btn>
    </v-app-bar>
    <!-- Content -->
    <template v-if="computedShowContent">
      <v-main>
        <v-container fluid :class="$vuetify.breakpoint.smAndUp ? '' : 'px-0'">
          <router-view :nests="computedNests"></router-view>
        </v-container>
      </v-main>
    </template>
    <template v-else>
      <Login></Login>
    </template>
    <!-- Footer -->
    <v-footer app>
    </v-footer>
  </v-app>
</template>

<script>
import Login from '@/components/Login.vue'
import NestPreview from '@/components/NestPreview.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Login,
    NestPreview
  },
  data: () => ({
    populatedUser: false,
    showDrawer: false,
    showRightDrawer: false
  }),
  mounted () {
    if (localStorage['feathers-jwt']) {
      this.authenticate()
    }
  },
  methods: {
    ...mapActions('auth', [
      'logout',
      'authenticate'
    ]),
    ...mapActions('companies', {
      findCompanies: 'find'
    }),
    ...mapActions('nests', {
      findNests: 'find'
    }),
    ...mapActions('users', {
      findUsers: 'find'
    }),
    ...mapActions('ac', {
      findAC: 'find'
    }),
    initLogin () {
      this.authenticate()
        .then(this.findAC({ paginate: false }))
        .then(this.findCompanies({ paginate: false }))
        .then(this.findNests({ paginate: false }))
        .then(this.findUsers({ paginate: false })
          .then((result) => {
            if (this.computedUser) {
              this.populatedUser = this.findUser(this.computedUser._id)
            }
          }))
    },
    initLogout () {
      this.$router.push('/')
      this.logout()
      setTimeout(() => {
        location.reload()
      }, 1000)
    }
  },
  watch: {
    computedUser () {
      if (this.computedUser && this.users) {
        this.initLogin()
      } else {
        this.populatedUser = false
      }
    }
  },
  computed: {
    ...mapGetters('users', {
      findUser: 'get',
      users: 'list'
    }),
    ...mapGetters('nests', {
      nests: 'list',
      getNest: 'get'
    }),
    ...mapGetters([
      'dynamicServicesReady',
      'ac'
    ]),
    computedTitle () {
      return process.env.VUE_APP_TITLE
    },
    computedUser () {
      if (this.$store.state.auth.user) {
        return this.$store.state.auth.user
      } else {
        return false
      }
    },
    computedShowApp () {
      return this.populatedUser
    },
    computedShowContent () {
      return (this.populatedUser && this.dynamicServicesReady) || (this.$route.name === 'verify' && this.$route.params.type && this.$route.params.token)
    },
    computedNests () {
      if (this.nests && this.$store.state.auth.user) {
        if (this.$store.state.auth.user.role !== 'administration') {
          const tmpNests = this.nests
          return tmpNests.filter(obj => !obj.name.startsWith('Test'))
        } else {
          return this.nests
        }
      } else {
        return []
      }
    }
  }
}
</script>

<style>
  .clickable {
    cursor: pointer !important
  }
</style>
