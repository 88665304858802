var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('v-card',[_c('v-form',{model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-card-title',[_vm._v(" Nutzer Editor ")]),_c('v-card-text',[(_vm.computedAcceptMode)?_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"dark":"","dense":"","tile":"","color":"primary","text":""}},[_vm._t("prepend",function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary","small":""}},[_vm._v(" fas fa-exclamation-circle ")])]}),_vm._v(" Neuer Nutzer! ")],2)],1)],1):_vm._e(),(_vm.$route.params.id !== 'new' && !_vm.computedUser.isActive)?_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"dark":"","dense":"","tile":"","color":"warning","text":""}},[_vm._t("prepend",function(){return [_c('v-icon',{staticClass:"mr-1",attrs:{"color":"warning","small":""}},[_vm._v(" fas fa-exclamation-triangle ")])]}),_vm._v(" Der Nutzer ist archiviert! ")],2)],1)],1):_vm._e(),(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
              (
                _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('firstName') &&
                _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('lastName')
              )
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').granted &&
            _vm.$store.state.auth.user._id === _vm.$route.params.id &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
              (
                _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('firstName') &&
                _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('lastName')
              )
            )
          )
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Vorname","rules":[_vm.rules.required, _vm.rules.shortText]},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Nachname","rules":[_vm.rules.required, _vm.rules.shortText]},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1)],1):_vm._e(),(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('company')
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').granted &&
            _vm.$store.state.auth.user._id === _vm.$route.params.id &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('company')
            )
          )
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Organisation auswählen","items":_vm.companies,"rules":[_vm.rules.required],"item-text":"name","item-value":"_id"},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1)],1):_vm._e(),(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('role')
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').granted &&
            _vm.$store.state.auth.user._id === _vm.$route.params.id &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('role')
            )
          )
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"label":"Rolle auswählen","items":[
              { name: 'Aufsuchende Arbeit', value: 'outreaching'},
              { name: 'Reinigung', value: 'cleaning'},
              { name: 'Reparatur / Wartung', value: 'maintenance' },
              { name: 'Supervision', value: 'supervision' },
              { name: 'Admin', value: 'administration' },
              { name: 'Sicherheit / Kontrolle', value: 'patrol' }
            ],"rules":[_vm.rules.required],"item-text":"name","item-value":"value"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}})],1)],1):_vm._e(),(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('email')
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').granted &&
            _vm.$store.state.auth.user._id === _vm.$route.params.id &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('email')
            )
          )
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"E-Mail","error-messages":_vm.emailError,"rules":[_vm.rules.required, _vm.rules.email]},on:{"input":function($event){return _vm.checkEmailError()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1):_vm._e(),(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('password')
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').granted &&
            _vm.$store.state.auth.user._id === _vm.$route.params.id &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('password')
            )
          )
        )?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Passwort","type":"password","rules":[_vm.rules.required, _vm.rules.shortText, _vm.rules.password]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1):_vm._e(),(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('subscriptions')
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').granted &&
            _vm.$store.state.auth.user._id === _vm.$route.params.id &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('subscriptions')
            )
          )
        )?[_c('v-row',[_c('v-col',[_vm._v(" Benachrichtigungen für den Nutzer aktivieren: ")])],1),_c('v-row',[_c('v-col',[(_vm.role === 'outreaching')?_c('v-checkbox',{attrs:{"disabled":true,"label":"Aufsuchende Arbeit"},model:{value:(_vm.trueDummy),callback:function ($$v) {_vm.trueDummy=$$v},expression:"trueDummy"}}):_c('v-checkbox',{attrs:{"label":"Aufsuchende Arbeit","value":"outreaching"},model:{value:(_vm.subscriptions),callback:function ($$v) {_vm.subscriptions=$$v},expression:"subscriptions"}})],1),_c('v-col',[(_vm.role === 'cleaning')?_c('v-checkbox',{attrs:{"disabled":true,"label":"Reinigung"},model:{value:(_vm.trueDummy),callback:function ($$v) {_vm.trueDummy=$$v},expression:"trueDummy"}}):_c('v-checkbox',{attrs:{"label":"Reinigung","value":"cleaning"},model:{value:(_vm.subscriptions),callback:function ($$v) {_vm.subscriptions=$$v},expression:"subscriptions"}})],1),_c('v-col',[(_vm.role === 'maintenance')?_c('v-checkbox',{attrs:{"disabled":true,"label":"Wartung"},model:{value:(_vm.trueDummy),callback:function ($$v) {_vm.trueDummy=$$v},expression:"trueDummy"}}):_c('v-checkbox',{attrs:{"label":"Wartung","value":"maintenance"},model:{value:(_vm.subscriptions),callback:function ($$v) {_vm.subscriptions=$$v},expression:"subscriptions"}})],1)],1)]:_vm._e()],2),_c('v-card-actions',{staticClass:"pa-4"},[(
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').granted &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateAny('users').attributes.includes('isActive')
            )
          ) ||
          (
            _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').granted &&
            _vm.$store.state.auth.user._id === _vm.$route.params.id &&
            (
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
              _vm.ac.can(_vm.$store.state.auth.user.role).updateOwn('users').attributes.includes('isActive')
            )
          )
        )?[(_vm.$route.params.id !== 'new')?[(_vm.computedUser.isActive)?_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":function($event){return _vm.trashUser()}}},[_vm._v(" Archivieren ")]):_c('v-btn',{attrs:{"outlined":"","color":"warning"},on:{"click":function($event){return _vm.untrashUser()}}},[_vm._v(" Aktivieren ")])]:_vm._e()]:_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary","disabled":!_vm.formValid},on:{"click":function($event){return _vm.saveUser()}}},[_vm._v(" Speichern ")])],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }