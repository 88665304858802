<template>
  <v-container>
    <v-card>
      <v-form v-model="formValid">
        <v-card-title>
          Nutzer Editor
        </v-card-title>
        <v-card-text>
        <v-row v-if="computedAcceptMode">
          <v-col>
           <v-alert
              dark
              dense
              tile
              color="primary"
              text
            >
              <slot name="prepend">
                <v-icon
                  color="primary"
                  class="mr-1"
                  small
                >
                  fas fa-exclamation-circle
                </v-icon>
              </slot>
              Neuer Nutzer!
              </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="$route.params.id !== 'new' && !computedUser.isActive">
            <v-col>
              <v-alert
              dark
              dense
              tile
              color="warning"
              text
            >
              <slot name="prepend">
                <v-icon
                  color="warning"
                  class="mr-1"
                  small
                >
                  fas fa-exclamation-triangle
                </v-icon>
              </slot>
              Der Nutzer ist archiviert!
              </v-alert>
            </v-col>
          </v-row>
        <v-row
          v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('users').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
                (
                  ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('firstName') &&
                  ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('lastName')
                )
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('users').granted &&
              $store.state.auth.user._id === $route.params.id &&
              (
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
                (
                  ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('firstName') &&
                  ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('lastName')
                )
              )
            )
          "
        >
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              label="Vorname"
              v-model="firstName"
              :rules="[rules.required, rules.shortText]"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              label="Nachname"
              v-model="lastName"
              :rules="[rules.required, rules.shortText]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('users').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('company')
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('users').granted &&
              $store.state.auth.user._id === $route.params.id &&
              (
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('company')
              )
            )
          "
        >
          <v-col
            cols="12"
          >
            <v-select
              label="Organisation auswählen"
              :items="companies"
              v-model="company"
              :rules="[rules.required]"
              item-text="name"
              item-value="_id"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('users').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('role')
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('users').granted &&
              $store.state.auth.user._id === $route.params.id &&
              (
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('role')
              )
            )
          "
        >
          <v-col
            cols="12"
          >
            <v-select
              label="Rolle auswählen"
              :items="[
                { name: 'Aufsuchende Arbeit', value: 'outreaching'},
                { name: 'Reinigung', value: 'cleaning'},
                { name: 'Reparatur / Wartung', value: 'maintenance' },
                { name: 'Supervision', value: 'supervision' },
                { name: 'Admin', value: 'administration' },
                { name: 'Sicherheit / Kontrolle', value: 'patrol' }
              ]"
              v-model="role"
              :rules="[rules.required]"
              item-text="name"
              item-value="value"
            ></v-select>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('users').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('email')
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('users').granted &&
              $store.state.auth.user._id === $route.params.id &&
              (
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('email')
              )
            )
          "
        >
          <v-col
            cols="12"
          >
            <v-text-field
              label="E-Mail"
              v-model="email"
              :error-messages="emailError"
              :rules="[rules.required, rules.email]"
              @input="checkEmailError()"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row
          v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('users').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('password')
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('users').granted &&
              $store.state.auth.user._id === $route.params.id &&
              (
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('password')
              )
            )
          "
        >
          <v-col
            cols="12"
          >
            <v-text-field
              label="Passwort"
              type="password"
              v-model="password"
              :rules="[rules.required, rules.shortText, rules.password]"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <template
          v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('users').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('subscriptions')
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('users').granted &&
              $store.state.auth.user._id === $route.params.id &&
              (
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('subscriptions')
              )
            )
          "
        >
          <v-row>
            <v-col>
              Benachrichtigungen für den Nutzer aktivieren:
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox v-if="role === 'outreaching'" :disabled="true" label="Aufsuchende Arbeit" v-model="trueDummy"></v-checkbox>
              <v-checkbox v-else v-model="subscriptions" label="Aufsuchende Arbeit" value="outreaching"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox v-if="role === 'cleaning'" :disabled="true" label="Reinigung" v-model="trueDummy"></v-checkbox>
              <v-checkbox v-else v-model="subscriptions" label="Reinigung" value="cleaning"></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox v-if="role === 'maintenance'" :disabled="true" label="Wartung" v-model="trueDummy"></v-checkbox>
              <v-checkbox v-else v-model="subscriptions" label="Wartung" value="maintenance"></v-checkbox>
            </v-col>
          </v-row>
        </template>
        </v-card-text>
        <v-card-actions class="pa-4">
          <template
            v-if="
            (
              ac.can($store.state.auth.user.role).updateAny('users').granted &&
              (
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateAny('users').attributes.includes('isActive')
              )
            ) ||
            (
              ac.can($store.state.auth.user.role).updateOwn('users').granted &&
              $store.state.auth.user._id === $route.params.id &&
              (
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('*') ||
                ac.can($store.state.auth.user.role).updateOwn('users').attributes.includes('isActive')
              )
            )
          "
          >
            <template v-if="$route.params.id !== 'new'">
              <v-btn
                v-if="computedUser.isActive"
                outlined
                color="error"
                @click="trashUser()"
              >
                Archivieren
              </v-btn>
              <v-btn
                v-else
                outlined
                color="warning"
                @click="untrashUser()"
              >
                Aktivieren
              </v-btn>
            </template>
          </template>
          <v-spacer>
          </v-spacer>
          <v-btn
            outlined
            color="primary"
            :disabled="!formValid"
            @click="saveUser()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  data: () => ({
    formValid: false,
    firstName: '',
    lastName: '',
    company: undefined,
    email: '',
    password: '',
    role: undefined,
    subscriptions: [],
    trueDummy: true,
    emailError: []
  }),
  mounted () {
    this.adapt()
  },
  methods: {
    ...mapActions('users', {
      createUser: 'create',
      patchUser: 'patch'
    }),
    ...mapActions('auth', [
      'authenticate'
    ]),
    adapt () {
      if (this.computedUser) {
        this.firstName = this.computedUser.firstName
        this.lastName = this.computedUser.lastName
        this.email = this.computedUser.email
        this.company = this.computedUser.company
        this.password = '********'
        this.role = this.computedUser.role
        this.subscriptions = this.computedUser.subscriptions
      }
    },
    saveUser () {
      const map = {
        firstName: this.firstName,
        lastName: this.lastName,
        company: this.company,
        email: this.email,
        password: this.password === '********' ? undefined : this.password,
        role: this.role,
        accepted: this.computedAcceptMode,
        subscriptions: this.subscriptions
      }
      if (this.$route.params.id !== 'new') {
        this.patchUser([this.$route.params.id, map, { }])
          .then((result) => this.$router.push('/users'))
      } else {
        this.createUser([map, {}])
          .then(result => this.$router.push('/users'))
          .catch((error) => {
            this.emailError = ['Es existiert bereits ein Zugang für ' + error.errors.email + '.']
          })
      }
    },
    trashUser () {
      const map = {
        isActive: false
      }
      this.patchUser([this.$route.params.id, map, { }])
        .then((result) => this.$router.push('/users'))
    },
    untrashUser () {
      const map = {
        isActive: true
      }
      this.patchUser([this.$route.params.id, map, { }])
        .then((result) => this.$router.push('/users'))
    },
    checkEmailError () {
      this.emailError = []
    }
  },
  computed: {
    ...mapGetters([
      'rules',
      'ac'
    ]),
    ...mapGetters('users', {
      users: 'list',
      getUser: 'get'
    }),
    ...mapGetters('companies', {
      companies: 'list'
    }),
    computedUser () {
      if (this.users) {
        return this.getUser(this.$route.params.id)
      } else {
        return {}
      }
    },
    computedAcceptMode () {
      if (this.computedUser) {
        return this.ac.can(this.$store.state.auth.user.role).updateAny('users').granted &&
          (
            this.ac.can(this.$store.state.auth.user.role).updateAny('users').attributes.includes('*') ||
            this.ac.can(this.$store.state.auth.user.role).updateAny('users').attributes.includes('isAccepted')
          ) &&
          this.computedUser.isAccepted === false
      } else {
        return false
      }
    }
  },
  watch: {
    computedUser () {
      this.adapt()
    }
  }
}
</script>
