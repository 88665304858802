<template>
  <v-card width="100%">
    <v-card-text>
      <v-row>
        <v-col>
          <div class="display-1">
            {{company.name}}
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div class="title mb-3">Users:</div>
          <v-row :key="i" v-for="(user, i) in computedCompanyUsers">
            <v-col class="py-0">
              {{ user.fullName }}
              <v-btn
                icon
                v-if="ac.can($store.state.auth.user.role).updateAny('users').granted"
              >
                <v-icon color="#bbb" small>fas fa-edit</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pa-4">
      <v-btn
        v-if="ac.can($store.state.auth.user.role).updateAny('companies').granted"
        outlined
        @click="$router.push('/companies/editor/' + company._id)"
      >
        Bearbeiten
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    showLastUpdate: false
  }),
  props: [
    'company'
  ],
  methods: {
  },
  computed: {
    ...mapGetters([
      'stateIcons',
      'stateTexts',
      'ac'
    ]),
    ...mapGetters('users', {
      users: 'list'
    }),
    computedCompanyUsers () {
      const companyUsers = []
      for (const user of this.users) {
        if (user.company === this.company._id) {
          companyUsers.push(user)
        }
      }
      return companyUsers
    }
  }
}
</script>
