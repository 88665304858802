const state = {
  ac: undefined,
  rules: {
    required: value => !!value || 'Pflichteingabe.',
    shortText: value => value.length <= 200 || 'Maximal 200 Zeichen.',
    longText: value => value.length <= 1000 || 'Maximal 1000 Zeichen.',
    email: value => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || 'Kein gültiges Email-Format'
    },
    password: value => (value.length >= 8 && value.length <= 16) || 'Erlaubte Passwörter haben eine Länge von 8-16 Zeichen und keine "*"'
  },
  dynamicServicesReady: false,
  stateIcons: {
    job: { true: 'fas fa-clipboard-list', false: 'fas fa-clipboard-list' },
    doorOpen: { true: 'fas fa-door-open', false: 'fas fa-door-closed' },
    doorEmergencyOpen: { true: 'fas fa-running', false: 'fas fa-running' },
    nukiLocked: {
      true: 'fas fa-key',
      false: 'fas fa-lock-open',
      '0x00': 'fas fa-key',
      '0x01': 'fas fa-lock',
      '0x02': 'fas fa-key',
      '0x03': 'fas fa-lock-open',
      '0x04': 'fas fa-key',
      '0x05': 'fas fa-key',
      '0x06': 'fas fa-key',
      '0x07': 'fas fa-key',
      '0xFC': 'fas fa-key',
      '0xFD': 'fas fa-key',
      '0xFE': 'fas fa-exclamation-triangle',
      '0x42': 'fas fa-exclamation-triangle',
      '0x49': 'fas fa-exclamation-triangle',
      '0xFF': 'fas fa-key'
    },
    doorLocked: { true: 'fas fa-lock', false: 'fas fa-lock-open' }, // Deprecated
    doorOpenerRelais: { true: 'fas fa-lock', false: 'fas fa-lock-open' }, // Deprecated
    doorButton: { true: 'fas fa-hand-point-down', false: 'fas fa-hand-point-down' },
    lightInside: { true: 'fas fa-lightbulb', false: 'far fa-lightbulb' },
    lightButton: { true: 'fas fa-toggle-on', false: 'fas fa-toggle-on' },
    lightButtonLed: { true: 'fas fa-sun', false: 'far fa-sun' },
    lightSignal: { true: 'fas fa-flag', false: 'far fa-flag' },
    motion: { true: 'fas fa-arrows-alt-h', false: 'fas fa-arrows-alt-h' },
    motionAtDoor: { true: 'fas fa-arrows-alt-h', false: 'fas fa-arrows-alt-h' },
    smokeReset: { true: 'fas fa-sync-alt', false: 'fas fa-sync-alt' },
    smoke: { true: 'fas fa-fire-alt', false: 'fas fa-fire-alt' },
    error: { true: 'fas fa-exclamation-triangle', false: 'fas fa-exclamation-triangle' },
    fan: { true: 'fas fa-fan', false: 'fas fa-fan' },
    battery: { true: 'fas fa-car-battery', false: 'fas fa-car-battery' },
    victronBatteryVoltage: { true: 'fas fa-car-battery', false: 'fas fa-car-battery' },
    victronSolarPower: { true: 'fas fa-sun', false: 'fas fa-sun' },
    victronChargingCurrent: { true: 'fas fa-v', false: 'fas fa-v' },
    victronExternalDeviceLoad: { true: 'fas fa-v', false: 'fas fa-v' },
    victronYieldToday: { true: 'fas fa-v', false: 'fas fa-v' },
    victronReceiveCount: { true: 'fas fa-v', false: 'fas fa-v' },
    nukiBatterystate: { true: 'fas fa-battery-full', false: 'fas fa-battery-full' },
    tempInside: { true: 'fas fa-thermometer-three-quarters', false: 'fas fa-thermometer-three-quarters' },
    tempOutside: { true: 'fas fa-thermometer-three-quarters', false: 'fas fa-thermometer-three-quarters' },
    tempBattery: { true: 'fas fa-charging-station', false: 'fas fa-charging-station' },
    humidityInside: { true: 'fas fa-water', false: 'fas fa-water' },
    humidityOutside: { true: 'fas fa-water', false: 'fas fa-water' },
    state: { true: 'fas fa-info', false: 'fas fa-info' },
    connect: { true: 'fas fa-wifi', false: 'fas fa-wifi' },
    startup: { true: 'fas fa-power-off', false: 'fas fa-power-off' },
    //
    outreaching: { true: 'fas fa-user-check', false: 'fas-fa-user-check' },
    cleaning: { true: 'fas fa-broom', false: 'fas fa-broom' },
    maintenance: { true: 'fas fa-tools', false: 'fas fa-tools' },
    updatedAt: { true: 'fas fa-satellite-dish', false: 'fas fa-satellite-dish' }
  },
  stateTexts: {
    doorOpen: { true: 'Tür geöffnet', false: 'Tür geschlossen' },
    doorEmergencyOpen: { true: 'Nottür geöffnet', false: 'Nottür geschlossen' },
    nukiLocked: {
      true: 'Verschlossen Nuki Boolean (Deprecated)', // Deprecated
      false: 'Uerschlossen Nuki Boolean (Deprecated)', // Deprecated
      '0x00': 'Fehler (0x00)',
      '0x01': 'Verschlossen',
      '0x02': 'Schließt auf',
      '0x03': 'Unverschlossen',
      '0x04': 'Schließt zu',
      '0x05': 'Unlatched',
      '0x06': 'Unlatched lock n go',
      '0x07': 'Unlatching',
      '0xFC': 'Kalibriert',
      '0xFD': 'Bootet',
      '0xFE': 'Nuki-Motor blockiert',
      '0x42': 'Nuki-Motor blockiert',
      '0x49': 'Undefinierte Nuki-Position',
      '0xFF': 'Undefiniert'
    },
    doorLocked: { true: 'Verschlossen Locked (Deprecated)', false: 'Unverschlossen Locked (Deprecated)' }, // Deprecated
    doorOpenerRelais: { true: 'Verschlossen Relais (Deprecated)', false: 'Unverschlossen Relais (Deprecated)' }, // Deprecated
    doorButton: { true: 'Türöffner gedrückt', false: 'Türöffner nicht gedrückt' },
    lightInside: { true: 'Licht innen an', false: 'Licht innen aus' },
    lightSignal: { true: 'Lichtsignal an', false: 'Lichtsignal aus' },
    lightButton: { true: 'Lichtschalter gedrückt', false: 'Lichtschalter nicht gedrückt' },
    lightButtonLed: { true: 'Lichtschalter beleuchtet', false: 'Lichtschalter nicht beleuchtet' },
    motion: { true: 'Bewegung Liegefläche', false: 'Keine Bewegung Liegefläche' },
    motionAtDoor: { true: 'Bewegung Eingang', false: 'Keine Bewegung Eingang' },
    smoke: { true: 'Rauchmelder ausgelöst', false: 'Rauchmelder nicht ausgelöst' },
    smokeReset: { true: 'Rauchmelder zurücksetzen', false: 'Rauchmelder zurücksetzen' },
    error: { true: 'Fehler', false: 'Kein Fehler' },
    fan: { true: 'Lüftungsgeschwindigkeit', false: 'Lüftungsgeschwindigkeit' },
    battery: { true: 'Akkustand', false: 'Akkustand' },
    victronBatteryVoltage: { true: 'Akkustand Victron', false: 'Akkustand Victron' },
    victronSolarPower: { true: 'Solar', false: 'Solar' },
    victronChargingCurrent: { true: 'Charging current', false: 'Charging current' },
    victronExternalDeviceLoad: { true: 'External device load', false: 'External device load' },
    victronYieldToday: { true: 'Yield today', false: 'Yield today' },
    victronReceiveCount: { true: 'Receive count', false: 'Receive count' },
    nukiBatterystate: { true: 'Schloss Akku', false: 'Schloss Akku' },

    tempInside: { true: 'Temperatur innen', false: 'Temperatur innen' },
    tempOutside: { true: 'Temperatur außen', false: 'Temperatur außen' },
    tempBattery: { true: 'Temperatur Akku', false: 'Temperatur Akku' },
    humidityInside: { true: 'Luftfeuchtigkeit innen', false: 'Luftfeuchtigkeit innen' },
    humidityOutside: { true: 'Luftfeuchtigkeit außen', false: 'Luftfeuchtigkeit außen' },
    //
    outreaching: { true: 'Aufsuchende Arbeit', false: 'Aufsuchende Arbeit' },
    cleaning: { true: 'Reinigung', false: 'Reinigung' },
    maintenance: { true: 'Reparatur / Wartung', false: 'Reparatur / Wartung' },
    administration: { true: 'Admin', false: 'Admin' },
    supervision: { true: 'Supervision', false: 'Supervision' },
    patrol: { true: 'Sicherheit / Kontrolle', false: 'Sicherheit / Kontrolle' },
    system: { true: 'System', false: 'System' },
    //
    connect: { true: 'Verbindung hergestellt', false: 'Verbindung abgebrochen' },
    startup: { true: 'Neustart', false: 'Neustart' },
    //
    state: {
      STATE_CLOSED_OTHER: 'Nicht zur Benutzung freigegeben',
      STATE_CLOSED_TEMP: 'Wegen ausreichender Außentemperatur oder des Zeitplans nicht verfügbar',
      STATE_CLOSED_CLEAN: 'Wegen Verschmutzung nicht verfügbar',
      STATE_CLOSED_MAINTENANCE: 'Wegen Reparatur / Wartung nicht verfügbar',
      STATE_AVAILABLE: 'Frei',
      STATE_AV_DOOR_OPEN: 'Tür wurde geöffnet',
      STATE_AV_DOOR_CLOSED: 'Tür wurde geschlossen - warten auf Bestätigung',
      STATE_OCC_MOTION: 'Belegt - Bewegung registriert',
      STATE_OCC_LOCKED: 'Belegt - Tür von innen verrriegelt',
      STATE_OCC_LIGHT: 'Belegt - Lichtschalter betätigt',
      STATE_OCC_DOOR_OPEN: 'Tür wurde nach Nutzung geöffnet',
      STATE_OCC_DOOR_CLOSED: 'Tür wurde nach Nutzung geschlossen - warten auf Bestätigung'
    },
    //
    job: { true: 'Aufgabe', false: 'Aufgabe' }
  }
}

const getters = {
  rules: state => {
    return state.rules
  },
  dynamicServicesReady: state => {
    return state.dynamicServicesReady
  },
  stateIcons: state => {
    return state.stateIcons
  },
  stateTexts: state => {
    return state.stateTexts
  },
  ac: state => {
    return state.ac
  }
}

const mutations = {
  SET_DYNAMIC_SERVICES_READY: (state, msg) => {
    state.dynamicServicesReady = msg
  },
  SET_AC: (state, msg) => {
    state.ac = msg
  }
}

const actions = {

}

export default {
  state,
  mutations,
  getters,
  actions
}
