<template>
  <v-card v-if="job">
    <v-card-text>
      <v-row>
        <v-col class="py-5" cols="10">
          <div class="headline mb-3">
            Aufgabe: {{ stateTexts[job.type].true }}
            <v-avatar
              v-if="job.done.dt"
              size="23px"
              color="success"
              class="mb-1 ml-1"
            >
              <v-icon x-small color="#fff">fas fa-check</v-icon>
            </v-avatar>
          </div>
          <div v-if="mode === 'list' || true" class="body-1">Nest: {{getNest(job.nest).name}}</div>
          <br>
          <ul>
            <li class="body-1">Eingereicht von <b>{{ getUser(job.from).fullName }}</b> am {{ $moment(job.createdAt).format('DD.MM.YYYY, HH:mm') }} Uhr mit Hinweis: <i>{{job.comment }}</i></li>
            <li v-if="job.done.dt" class="body-1">Erledigt von <b>{{getUser(job.done.by).fullName}}</b> am {{$moment(job.done.dt).format('DD.MM.YYYY, HH:mm')}} Uhr mit Hinweis: <i>{{job.done.comment}}</i></li>
            <li v-else class="body-1">Noch nicht erledigt.</li>
          </ul>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card
      v-if="!job.done.dt || job.communication && job.communication.length > 0"
      elevation="3"
      class="mx-4 mb-6"
    >
      <v-card-text>
        <v-row>
          <v-col class="font-weight-bold body-1">
            Kommentare
          </v-col>
        </v-row>
        <template
          v-if="job.communication && job.communication.length > 0"
        >
            <v-card
              v-for="(entry, i) in job.communication"
              :key="i"
              flat
              color="#eee"
              class="my-5 px-3"
            >
              <v-row>
                <v-col cols="12" class="caption pb-0">
                  <b>{{getUser(entry.from).fullName }}</b> schrieb am {{$moment(entry.dt).format('DD.MM.YYYY, HH:mm')}} Uhr:
                </v-col>
                <v-col cols="12" class="pt-0">
                  {{entry.text}}
                </v-col>
            </v-row>
          </v-card>
        </template>
        <v-row
          v-if="!job.done.dt"
        >
          <v-col cols="12">
            <v-textarea
              label="Kommentar hinzufügen ..."
              outlined
              v-model="communicationComment"
            >
            </v-textarea>
            <v-btn
              block
              :disabled="!communicationComment"
              @click="addCommunicationComment()"
            >
              Kommentar speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row
      class="px-4 pb-4"
    >
      <v-col
        cols="12"
        sm="6"
      >
        <v-btn
          v-if="ac.can($store.state.auth.user.role).updateAny('jobs').granted"
          @click="$router.push('/jobs/editor/' + job._id)"
          outlined
          class="pa-4 mt-0"
          :block="!$vuetify.breakpoint.smAndUp"
        >
          bearbeiten
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        class="text-right"
      >
        <v-btn
          v-if="
            !job.done.dt &&
            (
              ac.can($store.state.auth.user.role).updateAny('jobs').granted ||
              (
                ac.can($store.state.auth.user.role).updateOwn('jobs').granted &&
                (
                  job.from === $store.state.auth.user._id ||
                  job.type === $store.state.auth.user.role
                )
              )
            )
          "
          @click="showJobDialog = true"
          outlined
          class="pa-4 mt-0"
          :block="!$vuetify.breakpoint.smAndUp"
        >
          Als erledigt markieren
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="showJobDialog" max-width="1000">
          <v-stepper :value="jobStepperValue">
            <v-stepper-header>
              <v-stepper-step :complete="jobStepperValue > 1" step="1">Kommentar</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step v-if="job.followJobs && job.followJobs.length > 0" :complete="jobStepperValue > 2" step="2">Folge-Aufgaben</v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step :complete="jobStepperValue > 3" step="3">Bestätigung</v-stepper-step>
            </v-stepper-header>
              <v-stepper-content step="1">
                <v-card>
                  <v-card-title>
                    Kommentar zur Aufgabe (optional)
                  </v-card-title>
                  <v-card-text>
                    <v-textarea
                      label="Kommentarfeld"
                      v-model="comment"
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      @click="job.followJobs && job.followJobs.length > 0 ? jobStepperValue = 2 : jobStepperValue = 3"
                    >
                      Weiter
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content v-if="job.followJobs && job.followJobs.length > 0" step="2">
                <v-card>
                  <v-card-title>
                    Folge-Aufgaben definieren (optional)
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col v-if="job.followJobs && job.followJobs.indexOf('cleaning') !== -1" cols="12" sm="6">
                        <v-checkbox
                          v-model="cleaningJob"
                          label="Reinigung"
                        ></v-checkbox>
                        <v-textarea
                          v-if="cleaningJob"
                          label="Hinweis zur Reinigungs-Aufgabe (Pflichtangabe)"
                          v-model="cleaningText"
                        ></v-textarea>
                      </v-col>
                      <v-col v-if="job.followJobs && job.followJobs.indexOf('maintenance') !== -1" cols="12" sm="6">
                        <v-checkbox
                          v-model="maintenanceJob"
                          label="Reparatur / Wartung"
                        ></v-checkbox>
                        <v-textarea
                          v-if="maintenanceJob"
                          label="Hinweis zur Reparatur-Aufgabe (Pflichtangabe)"
                          v-model="maintenanceText"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      outlined
                      @click="jobStepperValue -= 1"
                    >
                      Zurück
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      @click="jobStepperValue += 1"
                      :disabled="((!cleaningText || cleaningText === '') && cleaningJob) || ((!maintenanceText || maintenanceText === '') && maintenanceJob)"
                    >
                      Weiter
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card>
                  <v-card-title>
                    Bitte {{stateTexts[job.type].true}} bestätigen
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-checkbox
                          v-model="confirmJob"
                          :label="stateTexts[job.type].true + ' wurde ausgeführt'"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      outlined
                      @click="job.followJobs && job.followJobs.length > 0 ? jobStepperValue = 2 : jobStepperValue = 1"
                    >
                      Zurück
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      :disabled="!confirmJob"
                      @click="preparePatchJob()"
                    >
                      Bestätigen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="4">
                <v-card>
                  <v-card-title>
                    Vielen Dank!
                  </v-card-title>
                  <v-card-text class="text-center">
                    <v-icon
                      size="100px"
                      color="success"
                    >fas fa-check-circle</v-icon>
                  </v-card-text>
                </v-card>
              </v-stepper-content>
          </v-stepper>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    showJobDialog: false,
    jobStepperValue: 1,
    cleaningJob: false,
    maintenanceJob: false,
    comment: '',
    cleaningText: '',
    maintenanceText: '',
    confirmJob: false,
    showJobDetails: true,
    communicationComment: undefined
  }),
  props: [
    'job',
    'mode'
  ],
  mounted () {
    this.jobStepperValue = 1
  },
  methods: {
    ...mapActions('jobs', {
      patchJob: 'patch'
    }),
    async addCommunicationComment () {
      await this.patchJob([
        this.job._id,
        {
          $push: {
            communication: {
              from: this.$store.state.auth.user._id,
              dt: new Date().toISOString(),
              text: this.communicationComment
            }
          }
        }
      ])
      this.communicationComment = undefined
    },
    preparePatchJob () {
      this.jobStepperValue += 1
      const todos = []
      if (this.cleaningJob) {
        todos.push({ type: 'cleaning', comment: this.cleaningText })
      }
      if (this.maintenanceJob) {
        todos.push({ type: 'maintenance', comment: this.maintenanceText })
      }
      this.patchJob([
        this.job._id,
        {
          done: {
            dt: true,
            by: this.$store.state.auth.user,
            comment: this.comment,
            todos: todos
          }
        }
      ])
        .then((result) => {
          setTimeout(() => {
            this.showJobDialog = false
            setTimeout(() => {
              this.jobStepperValue = 1
            }, 500)
          }, 1000)
        })
    }
  },
  computed: {
    ...mapGetters([
      'stateIcons',
      'stateTexts',
      'ac'
    ]),
    ...mapGetters('users', {
      getUser: 'get'
    }),
    ...mapGetters('nests', {
      getNest: 'get'
    })
  }
}
</script>
