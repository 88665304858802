<template>
  <v-card>
    <v-card-actions
      class="pa-4"
    >
      <template v-if="!showButtons">
      <v-btn
        block
        outlined
        @click="showButtons = true"
      >
        Update (pull + reboot)
      </v-btn>
      </template>
      <template v-else>
        <v-btn
          outlined
          @click="showButtons = false"
        >
          Abbrechen
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          @click="prepareUpdate()"
        >
          Bestätigen
        </v-btn>
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  props: [
    'nest'
  ],
  data: () => ({
    controlPath: true,
    showButtons: false
  }),
  computed: {
    computedNestPath () {
      return this.nest.path
    }
  },
  methods: {
    ...mapActions('publisher', {
      publish: 'create'
    }),
    prepareUpdate () {
      this.publish({ topic: 'sysupdate/' + this.computedNestPath, message: 'true' })
        .then(() => { this.showButtons = false })
    }
  }
}
</script>

<style>
</style>
