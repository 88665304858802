<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col>
          <v-select
            label="Topic"
            :items="items"
            v-model="topic"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-combobox
            v-model="message"
            label="Wert"
            :items="['true', 'false']"
          >
          </v-combobox>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions
      class="pa-4"
    >
      <v-checkbox
        v-model="controlPath"
        label="control/ Pfad verwenden"
      ></v-checkbox>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="publish({ topic: computedControlPath + computedNestPath + '/' + topic, message: message })"
      >
        Abschicken
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  props: [
    'nest'
  ],
  data: () => ({
    controlPath: true,
    topic: undefined,
    message: undefined,
    items: [
      {
        text: 'Temperatur innen',
        value: 'tempInside'
      },
      {
        text: 'Temperatur außen',
        value: 'tempOutside'
      },
      {
        text: 'Luftfeuchtigkeit innen',
        value: 'humidityInside'
      },
      {
        text: 'Luftfeuchtigkeit außen',
        value: 'humidityOutside'
      },
      {
        text: 'Licht innen',
        value: 'lightInside'
      },
      {
        text: 'Lichtsignal außen',
        value: 'lightSignal'
      },
      {
        text: 'Bewegung Liegefläche',
        value: 'motion'
      },
      {
        text: 'Bewegung Eingang',
        value: 'motionAtDoor'
      },
      {
        text: 'Rauchmelder',
        value: 'smoke'
      },
      {
        text: 'Tür geöffnet',
        value: 'doorOpen'
      },
      {
        text: 'Nottür geöffnet',
        value: 'doorOpenEmergency'
      },
      {
        text: 'Tür verriegelt',
        value: 'nukiLocked'
      },
      {
        text: 'Lüfter',
        value: 'fan'
      },
      {
        text: 'Akku',
        value: 'battery'
      },
      {
        text: 'Temperatur Akku',
        value: 'tempBattery'
      },
      {
        text: 'Fehler',
        value: 'error'
      },
      {
        text: 'Rauchmelder zurücksetzen',
        value: 'smokeReset'
      },
      {
        text: 'Status',
        value: 'state'
      },
      {
        text: 'Receive count',
        value: 'victronReceiveCount'
      },
      {
        text: 'Yield today',
        value: 'victronYieldToday'
      },
      {
        text: 'External device load',
        value: 'victronExternalDeviceLoad'
      },
      {
        text: 'Charging current',
        value: 'victronChargingCurrent'
      },
      {
        text: 'Solar',
        value: 'victronSolarPower'
      },
      {
        text: 'Akku Victron',
        value: 'victronBatteryVoltage'
      },
      {
        text: 'Akku Schloss',
        value: 'nukiBatterystate'
      }
    ]
  }),
  computed: {
    computedNestPath () {
      return this.nest.path
    },
    computedControlPath () {
      if (this.controlPath) {
        return 'control/'
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapActions('publisher', {
      publish: 'create'
    })
  },
  watch: {
  }
}
</script>

<style>
</style>
