// Bring in the imports from the feathers-client.js file.
import feathersClient, {
  makeServicePlugin,
  BaseModel
} from '../../feathers-client'

import Store from '../store'
const AccessControl = require('accesscontrol')

// Extend the base class
class AC extends BaseModel {
  // constructor (data, options) {
  //   super(data, options)
  // }
  static modelName = 'AC'
  static instanceDefaults () {
    return {
    }
  }
}
const servicePath = 'ac'
const servicePlugin = makeServicePlugin({
  Model: AC,
  service: feathersClient.service(servicePath),
  servicePath
})

// Optionally add service-level hooks, here:
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [(context) => {
      const ac = new AccessControl(context.result[0])
      ac.lock()
      Store.commit('SET_AC', ac)
    }],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
